import React, { useState } from "react";
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, FormControl, Typography, Button } from '@mui/material';
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { getCookie } from "../../components/Cookies";
import IconButton from '@mui/material/IconButton';




const CreateAgent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
const token = getCookie("leadmanagement")

  const [manageData, setManageData] = useState({
    firstName: "", lastName: "", email: "", mobileNumber: "",position:"", department:"" , password:""

  
    
  })
  const handleSave = async () => {

    if(
      !manageData.firstName
    ){
      return toast.error("first name must be required")
    }else if(
      !manageData.lastName
    ){ 
      return toast.error("last name must be required")
    }else if(
      !manageData.email
    ){
      return toast.error("email must be required")
    }else if(
      !manageData.mobileNumber
    ){
      return toast.error("phone number must be required")
    }else if(
      !manageData.position
    ){
      return toast.error("Position must be required")
    }
    else if(
      !manageData.department
    ){
      return toast.error("department must be required")
    }
    else if(
      !manageData.password
    ){
      return toast.error("password must be required")
    }
    

    try {
      const response = await Api.create_Agent(manageData , token);
      console.log("Response from createManager API:", response.data.message);

      if (response.data.message === "agent created successfully") {
        toast.success('Successfully Created');
        navigate("/agents")
      }
      else {
        console.log("error")

      }


    } catch (error) {
      console.error('Error during creating user:', error);
    }
  };


  const backToPage = () => {
    navigate("/agents")
  }

  return (
    <>
     <Topbar />
      <Box m="20px" className="margin_in_mobile_ipad">
     
        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Contact Information

</Typography>

<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={manageData.firstName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setManageData({ ...manageData, firstName: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Last Name"
  value={manageData.lastName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setManageData({ ...manageData, lastName: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Email"
  value={manageData.email}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 35) {
      setManageData({ ...manageData, email: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Phone"
  value={manageData.mobileNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setManageData({ ...manageData, mobileNumber: inputText });
    }
  }}
  variant="outlined"
/>


<TextField
      type={showPassword ? "text" : "password"}
      // placeholder="Enter Password"
      className="class_input"
id="outlined-controlled"
label="Password"
value={manageData.password}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
  setManageData({ ...manageData, password: inputText });
}
}}
variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />

</Box></Box>

<Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
        <Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Additional Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

<Grid >
  <div>Position</div>
  <FormControl fullWidth >

    <Select
    sx={{height:"40px"}}
      labelId="second-select-label"
      id="second-select"
      fullWidth
      value={manageData.position}
      onChange={(e) => setManageData({ ...manageData, position: e.target.value })}
    >
      <MenuItem value="Agent">Agent</MenuItem>


    </Select>
  </FormControl>
</Grid>

<Grid >
  <div>Department</div>
  <FormControl fullWidth>

    <Select
         sx={{height:"40px"}}
      labelId="third-select-label"
      id="third-select"
      fullWidth
      defaultValue=""
      value={manageData.department}
      onChange={(e) => setManageData({ ...manageData, department: e.target.value })}
    >
      <MenuItem value="sales">Sales</MenuItem>
      <MenuItem value="marketing">Marketing</MenuItem>
      <MenuItem value="product_development">Product Development</MenuItem>
      <MenuItem value="management">Management</MenuItem>

    </Select>
  </FormControl>
</Grid>



</Box>
        </Box>

</div>

        
          <br />


            <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>
        
      
            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
         

        </div>
   
        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};

export default CreateAgent;
