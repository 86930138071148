import { Box, Button, Typography } from "@mui/material";
import { DialogTitle, Dialog, DialogContent, DialogActions } from "@mui/material";
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import Topbar from "../global/Topbar";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Api from "../../Api";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useEffect, useState } from "react";
import { getCookie } from "../../components/Cookies";
import Swal from "sweetalert2";






const Dashboard = ({ isLogging }, params) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [manager, setManager] = useState("")
  const [agent, setAgent] = useState("")
  const [totalLeads, setTotalLeads] = useState("")
  const [assignLeads, setAssignLeads] = useState("0")
  const [source, setSource] = useState("")
  const [todayLeads , setTodayLeads] = useState("")
  const navigate = useNavigate()




  const icons  = {
    facebookIcon: <img src = "/img/Group.png" alt =" "/>,
    instagramIcon: <img src = "/img/insta.png" alt =" "/>,
    whatsappIcon:<img src = "/img/whatsapp.png" alt =" "/>,
    linkedinIcon: <img src = "/img/linkedin.png" alt =" "/>,
    googleIcon: <img src = "/img/googlr.png" alt =" "/>,
    websiteIcon:<img src = "/img/website.png" alt =" "/>,
  };
  
  
  const getLabelWithIcon = (iconUrl, leadCount) => {
    return `
      <div style="display: flex; align-items: center;">
        <img src="${iconUrl}" style="width: 20px; height: 20px; margin-right: 5px;" alt="icon" />
        <span>${leadCount}</span>
      </div>
    `;
  };




  const counts = [
    { id: 1, count: totalLeads, description: "Total Leads", image: "/img/icon4.png" },
    { id: 2, count: assignLeads, description: "Assigned Leads", image: "/img/greenimage.png" },
    { id: 3, count: manager, description: "Total Manager", image: "/img/redimage.png" },
    { id: 4, count: agent, description: "Total Agents", image: "/img/blueimage.png" },
  ];

  const token = getCookie("leadmanagement")
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([])
  useEffect(() => {
    if (isLogging) {
      fetchData(); 
      handleManager()
      handleAgent()
      handleAllLead()
      handleAllAssignLead()
      handleSource()
      handleTodayLeads()
    }
  }, [isLogging, allNotes, isDelete, manager, agent, totalLeads, assignLeads,]);

  const openPopup = () => {
    Swal.fire({
      title: "Add Note",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        return note;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'swal-button',
        cancelButton: 'swal-button'
      },
      buttonsStyling: false,
      padding: '2rem',
 
      background: '#fff',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      confirmButtonColor: '#4caf50',
      confirmButtonTextColor: '#fff',
      cancelButtonColor: '#f44336',
      cancelButtonTextColor: '#fff',
      html: `
        <style>
          .swal-button {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }
        </style>
      `
    }).then(async (result , error) => {
      if (result.isConfirmed) {
        try {
          const response = await Api.createTextNote({ text: result.value }, token);
          setIsDelete(!isDelete)
          console.log("Response from createTextNote API:", response.data.message);
          if (response.data.message === "successfully created note") {
            setAllNotes(!allNotes)
            Swal.fire('Note Saved!', '', 'success');
            navigate("/admin-dashboard");
          } else {
            console.log("error");
          }
        } catch (error) {
          console.error('Error during saving note:', error);
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      }
    });
  }

const handleTodayLeads = async ()=>{
try {
  
  const response = await Api.today_Leads(token)
  console.log("today leads", response.data);
  setTodayLeads(response.data)
} catch (error) {
  console.error('Error fetching data:', error);
}

}



  const handleSource = async () => {

    try {
      const response = await Api.get_Lead_Status_source_Admin(token)
      console.log(response.data)
      setSource(response.data)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAllAssignLead = async () => {
    try {
      const response = await Api.assign_Leads(token)

      setAssignLeads(response.data.count)


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAllLead = async () => {
    try {
      const response = await Api.total_Leads(token)

      setTotalLeads(response.data.count)


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAgent = async () => {

    try {
      const response = await Api.get_Manager_Agent(token)
    
      setAgent(response.data.count)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }




  const handleManager = async () => {

    try {
      const response = await Api.getAllManager(token)
     
      setManager(response.data.count)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const fetchData = async () => {
    try {
      const response = await Api.getNotesByUser(token);
      if (response.data && Array.isArray(response.data.data)) {
        setNotes(response.data.data);
      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Api.deleteNote(selectedUserId, token);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);

    } catch (error) {

      console.error("Error deleting user:", error);
    }
  };

 






  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad" >


       

        <Box>

          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", gap: "20px", }} className="totalInputsOfDash">
            {counts.map((data, index) => (
              <div key={data.id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", padding: "10px", borderRadius: "10px", }}>
                <img style={{ height: "40px", width: "40px" }} src={data.image} alt="" />
                <div style={{ textAlign: "end" }}  >
                  <p><strong>{data.count}</strong></p>
                  <p>{data.description}</p>
                </div>
              </div>
            ))}


          </div>
        </Box>
        <br />
        <Box >

<div style={{ display: "flex", justifyContent: "space-between" }}>

  <Typography variant="h3" gutterBottom>
     Sticky Notes
  </Typography>

  <Button onClick={openPopup} sx={{ backgroundColor: "#9B56FF", color: "#fff", height: "40px", ':hover': { backgroundColor: "#9B56FF", color: "#fff" } }}>Add Notes</Button>

</div>

<br />

<Box display="flex" flexWrap="wrap" style={{  gap:"20px"}}>
  {notes.map((note, index) => (
    <Card key={index} sx={{  padding: "10px", backgroundColor: "#28c76f", color: "white" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <CloseOutlinedIcon style={{ color: "red", fontSize: "10px" , cursor: "pointer" }}
          onClick={() => handleDeleteClick(note._id)}
        />
      </div>

      <CardContent>
        <Typography variant="body2">
          {note.text}
        </Typography>
      </CardContent>
    </Card>
  ))}
</Box>
</Box>

<br />

        <Box>

          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: "20px" }} className='invoicesalign'  >

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Lead Sources </h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value: source.facebook, label: `Facebook ${source.facebookLeads}`,  color: "#0f4a96" },
                        { id: 1, value: source.instagram , label: `Instagram ${source.instagramLeads}`, color: "#c255bb" },
                        { id: 2, value: source.whatsapp, label: `Whatsapp ${source.whatsappLeads}`, color: "#416634" },
                        { id: 3, value: source.linkedin, label: `Linkedin ${source.linkedinLeads}`, color: "#9973d1" },
                        { id: 4, value: source.google, label: `Google ${source.googleLeads}`, color: "#ccffcc" },
                        { id: 5 ,value: source.website, label: `Website ${source.websitesLeads}`, color: "#d9ff66" },

                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>

            </div>

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Leads Status</h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value:source.newPercentage, label: `New  ${source.newLeads}`, color: "rgb(5, 102, 129)" },
                        { id: 1, value: source.processingPercentage, label: `In Discussion  ${source.processingLeads}`, color: "rgb(58, 50, 133)" },
                        { id: 2, value: source.confirmPercentage, label: `Hired  ${source.confirmLeads}`, color: "rgb(72, 110, 3)" },
                        { id: 3, value: source.cancelPercentage, label: `Cold/Dead  ${source.cancelLeads}`, color: "rgb(230, 0, 35)" },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>

            </div>

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Today Leads</h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value: todayLeads.facebook, label: `Facebook ${todayLeads.facebookLeads}`,  color: "#0f4a96" },
                        { id: 1, value: todayLeads.instagram , label: `Instagram ${todayLeads.instagramLeads}`, color: "#c255bb" },
                        { id: 2, value: todayLeads.whatsapp, label: `WhatsApp ${todayLeads.whatsappLeads}`, color: "#416634" },
                        { id: 3, value: todayLeads.linkedin, label: `Linkdin ${todayLeads.linkedinLeads}`, color: "#9973d1" },
                        { id: 4, value: todayLeads.google, label: `Google ${todayLeads.googleLeads}`, color: "#ccffcc" },
                        { id: 5 ,value: todayLeads.website, label: `Website ${todayLeads.websitesLeads}`, color: "#d9ff66" },

                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>

            </div>

          </div>
          <br />

        </Box>
        <br />

        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

    </>
  );
};

export default Dashboard;
