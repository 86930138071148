import React from "react";
import { useState} from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { connect } from "react-redux";
import { tokens } from "../../theme";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotesIcon from '@mui/icons-material/Notes';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

const DropdownItem = ({ title, to, icon, selected, setSelected, children }) => {
  console.log()
  console.log(children)
  const theme = useTheme(); 
  const colors = tokens(theme.palette.mode);
  
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setSelected(title)
    setOpen(!open);
  };
 

  return (
    <>
      <MenuItem
       active={selected === title}
        style={{
          color: colors.grey[100] ,
       
        }}
       
        icon={icon}
        onClick={handleToggle}
        
      >
       
        <Typography style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>{title} <ExpandMoreIcon /></Typography>
        
      </MenuItem>
      

      {open && children && children.map((child, index) => ( 
        <MenuItem
          key={index}
          active={selected === child.title}
          style={{
            color: colors.grey[100],
          }}
          onClick={() => setSelected(child.title)}
          
        >
          <Typography  style={{padding:"10px 0px 0px 40px"}}>{child.title}</Typography>
          <Link to={child.to} />
        </MenuItem>
      ))}
    </>
  );
};

const Item = ({ title, to, icon, selected, setSelected, childItem }) => { 
  
  console.log(childItem)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (childItem && childItem.length > 0) {
    return (


      

   
      DropdownItem ( {  title:title,
        to:to,
        icon:icon,
        selected:selected,
        setSelected:setSelected,
        children :childItem })

     
    );
  } else {


    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
      
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  }
};



const MobileSidebar = ({role}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(  "Dashboard" );
 


 











  return (
    <Box

    
    
      sx={{
        // "& .pro-sidebar-inner": {
        //   background: `${colors.primary[400]} !important`,
        // },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 0px 5px 10px !important",
        },
        // "& .pro-inner-item:hover": {
        //   backgroundColor: "#9B56FF !important",
        //   color:"white"
        // },
        "& .pro-menu-item.active": {
          backgroundColor: "#9B56FF !important",
          color: "white !important "
        },
        

      
        
      
  

   

      }}
      className='yash '
    >

      { role=== "admin" ?
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
        

          {/* {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Homlea
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Admin
                </Typography>
              </Box>
            </Box>
          )} */}





          {/* <div style={{display:isCollapsed?"none" :"flex"
  // "flex"
   , justifyContent:"center"}}>
      <img src="/img/logo.png" alt="" />
     </div> */}


          {/* <br /> */}




          <Box paddingLeft={isCollapsed ? undefined : "2%"} >



          <Item
              title="Dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
              // childItem={[{ title: "Deals Dashboard", to: "/dashboard" }, { title: "Projects Dashboard", to: "/project-dashboard" },
              // { title: "Leads Dashboard", to: "/leads-dashboard" },
              // ]}
            >


</Item>
    

{/* <Item
  title="Dashboard"
  icon={<HomeOutlinedIcon />}
  selected={selected}
  setSelected={setSelected}
  childItem = {[{title:"Deals Dashboard" , to:"/dashboard"} , {title:"Projects Dashboard" , to:"/project-dashboard"},
  {title:"Leads Dashboard" , to:"/leads-dashboard"} ,
    ]}
>




</Item> */}

   
            {/* <Item
              title="Task"
              to="/task"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            


           
            <Item
              title="Leads"
              to="/leads"
              icon={<LeaderboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
            title="Confirm Lead"
            to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />
<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Reminders"
            to="/reminders"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
              icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Item
              title="Reports"
              to="/reports"
              icon={<SummarizeIcon />}
              selected={selected}
              setSelected={setSelected}
            />

    



<Item
              title="Settings"
              to="/setting"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            

          </Box>
        </Menu>
      </ProSidebar>
        :  
        
        role === "manager" ? 
    (  
      <ProSidebar collapsed={isCollapsed}>
      <Menu iconShape="square">
        {/* LOGO AND MENU ICON */}
      

        <Box paddingLeft={isCollapsed ? undefined : "2%"} >
    <Item
        title="Dashboard"
        to="/manager-dashboard"
        icon={<DashboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />


   
      <Item
        title="Leads"
        to="/leads"
        icon={<LeaderboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
            title="Confirm Lead"
            to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      
      <Item
              title="Reminders"
            to="/reminders"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
              icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />



<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />

     

      {/* <Item
        title="Projects"
        to="/projects"
        icon={<AccountTreeOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}

      <Item
        title="Users"
        to="/agents"
        icon={<GroupOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      {/* <Item
        title="Reports"
        to="/reports"
        icon={<ReportOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}


      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
      </Menu>
    </ProSidebar>
     )   : 
    
    
    
    (
      
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
        

          


          <Box paddingLeft={isCollapsed ? undefined : "2%"} >
    {/* <Item
        title="Home"
        to="/agent-dashboard"
        icon={<ReceiptOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}


      {/* <Item
        title="Dashboard"
        icon={<HomeOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        childItem={[
          { title: "Manager Dashboard", to: "/manager-dashboard" },
         { title: "Projects Dashboard", to: "/project-dashboard" },
        { title: "Leads Dashboard", to: "/leads-dashboard" },
        ]}
      >
      </Item> */}


      {/* <Item
        title="Contacts"
        to="/contact"
        icon={<ContactPageOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}


      {/* <Item
        title="Companies"
        to="/companies"
        icon={<ContactsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}


      <Item
        title="Leads"
        to="/leads"
        icon={<LeaderboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
            title="Confirm Lead"
            to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
        title="Reminder"
        to="/reminders"
        icon={<CalendarTodayIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
        title="Meetings"
        to="/meetings"
        icon={<MeetingRoomIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
        title="Follow Ups"
        to="/follow-ups"
        icon={<BookmarkIcon />}
        selected={selected}
        setSelected={setSelected}
      />



<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      {/* <Item
        title="Projects"
        to="/projects"
        icon={<AccountTreeOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}

      {/* <Item
        title="agent"
        to="/agents"
        icon={<GroupOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}

      {/* <Item
        title="Reports"
        to="/reports"
        icon={<ReportOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      /> */}


      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
        </Menu>
      </ProSidebar>

)   }
    </Box>
  );
};
const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(MobileSidebar) ;
