

import InputLabel from '@mui/material/InputLabel';
import React, { useState, useEffect } from "react";
import Loader from "../Loader";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { TextField, Select, MenuItem } from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";


const Leads = ({ isLogging, role }) => {
  const token = getCookie("leadmanagement");
  const openPopup = () => {
    navigate("/create-lead");
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
console.log("status" , status)
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [lead, setLead] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [followUP, setFollowUp] = useState({
    description: "",
    time: "",
    dueDate: "",
    email: "",
  });

  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [leadDataAssignTo, setLeadDataAssignTo] = useState("");
  const [users, setUsers] = useState([]);
  const [age, setAge] = React.useState('');

  const handleDropdown = (event) => {
    setAge(event.target.value);}


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [isDelete ,status]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
      if (role === "admin") {
        response = await Api.get_All_Leads(token , status);
      } else if (role === "manager") {
        response = await Api.get_Manager_Leads(token , status);
      } else if (role === "agent") {
        response = await Api.get_Agent_Leads(token ,status);
      }

      if (response.data && Array.isArray(response.data.data)) {
        setLoading(false);
        setLead(mapPlotData(response.data.data));
        console.log(response.data.data);
      } else {
        console.error(
          "Data is not an array or is missing:",
          response.data.data
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === "admin") {
          response = await Api.getAllUsers(token);
        } else if (role === "manager") {
          response = await Api.getAllManagerAgent(token);
        } else if (role === "agent") {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [role]);

  const handleAssignTo = async () => {


console.log(selectionModel)
console.log(leadDataAssignTo)


    if ( !leadDataAssignTo) {
      toast.error("Please select at least one user.");
      return;
    }
    if (
      selectionModel.length === 0
    ) {
      toast.error("Please select at least one lead.");
      return;
    }

    setLoading(true);

    try {
      const payload = {
        assignTo: leadDataAssignTo,
        leadIds: selectionModel,
      };

      const response = await Api.assignMultipleLeads(payload, token);

      if (response.data.message === "Leads successfully assigned") {
        toast.success("Leads successfully assigned!");
        setSelectionModel([]);
        setLeadDataAssignTo("")
        fetchData();
      } else {
        toast.error("Failed to assign leads.");
      }
    } catch (error) {
      console.error("Error assigning leads:", error);
      toast.error("Failed to assign leads.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleCreateFollowups = async () => {
    setLoading(true);
    try {
      const response = await Api.create_Follow(followUP, token);

      console.log("response of api to create reminder", response);

      if (response.data.message === "followup create successfully") {
        setOpen(false);
        setLoading(false);
        toast.success("Successfully Created");
        fetchData();
        navigate("/follow-ups");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleInputeChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    setFollowUp({ ...followUP, [e.target.name]: e.target.value });
  };

  const handleCreateClick = (id) => {
    const selectedLead = lead.find((item) => item.id === id);
    setSelectedUserId(id);
    setFollowUp({ ...followUP, email: selectedLead.email });
    setOpen(true);
  };

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await Api.Delete_lead(selectedUserId);
      setIsDelete(!isDelete);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
      console.error("Error deleting user:", error);
    }
  };

const handleDropdownOne =(e)=>{
  if(e.target.value === "all"){
    navigate("/leads")
  }else{
    navigate(`/leads/?status=${e.target.value}`)
  }
 
 return;

}
      
      




  const handleLeadStatusChange = async (event, id) => {
    const newStatus = event.target.value;
    setLoading(true);
    try {
      const response = await Api.update_Status(
        id,
        { leadStatus: newStatus },
        token
      );
      const updatedLead = lead.map((item) =>
        item.id === id ? { ...item, lead_status: newStatus } : item
      );
      setLead(updatedLead);

      if (response.data.message == "Lead status updated successfully") {
        toast.success("Lead Status updated successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating lead status:", error);
    }
  };

  const handleUploadCsv = async () => {
    if (!fileUpload) {
      return toast.error("No file selected");
    }

    if (fileUpload.type !== "text/csv") {
      return toast.error("Please choose a CSV file");
    }

    const formData = new FormData();
    formData.append("csvfile", fileUpload);
    try {
      const response = await Api.uploadCsvToJson(formData, token);
      console.log(response.data.data);
      setFileUpload(response.data.data);
      toast.success("File uploaded successfully");
      fetchData();
      navigate("/leads");
      return;
    } catch (error) {
     return console.log(error);
    }
  };

  

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "sno", headerName: "S.No", width: 30 },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 120,
      renderCell: (params) => (
        <Link to={`/lead-details/${params.row.id}`}>{params.value}</Link>
      ),
    },
    { field: "email", headerName: "Email", width: 120 },
    { field: "lead_source", headerName: "Lead Source", width: 120 },
    { field: "isAssign", headerName: "Assigned By", width: 120 },
    { field: "assignTo", headerName: "Assigned To", width: 120 },
    {
      field: "lead_status",
      headerName: "Lead Status",
      width: 170,
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) => handleLeadStatusChange(e, params.row.id)}
          style={{
            backgroundColor: getButtonColor(params.value),
            padding: "2px",
            width: "130px",
            color: "white",
            height: "35px",
          }}
        >
          <MenuItem value="new" >New</MenuItem>
          <MenuItem value="processing">In discussion</MenuItem>
          <MenuItem value="confirm" >Hired</MenuItem>
          <MenuItem value="cancel" >Cold/Dead</MenuItem>
          <MenuItem value="hot">Hot</MenuItem>
                      <MenuItem value="hold">Hold</MenuItem>
                      <MenuItem value="negotiation">Negotiation</MenuItem>
                      <MenuItem value="lost/won">Lost/Won</MenuItem>
                      <MenuItem value="dispatch/delivered">Dispatch/Delivered</MenuItem>
                      <MenuItem value="technical">Technical Evaluation</MenuItem>
                      <MenuItem value="finincial">Finincial Evaluation</MenuItem>
                      <MenuItem value="visit">Visit Planning</MenuItem>
                      <MenuItem value="revised">Revised Quotation</MenuItem>
                      <MenuItem value="oppurtunity">Oppurtunity</MenuItem>
        </Select>
      ),
    },
    { field: "lead_created", headerName: "Lead Created", width: 120 },
    {
      field: "add_followup",
      headerName: "Add Followup",
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleCreateClick(params.row.id)}
          style={{ color: "black" }}
        >
          <AddIcCallIcon />
        </button>
      ),
    },
    {
      field: "edit_lead",
      headerName: "Edit Lead",
      width: 100,
      renderCell: (params) => (
        <Link to={`/edit-lead/${params.row.id}`} style={{ color: "black" }}>
          <EditIcon />
        </Link>
      ),
    },
    ...(role === "admin"  || "manager"
      ? [
          {
            field: "delete_lead",
            headerName: "Delete Lead",
            width: 100,
            renderCell: (params) => (
              <button
                onClick={() => handleDeleteClick(params.row.id)}
                style={{ color: "black" }}
              >
                <DeleteForeverOutlinedIcon />
              </button>
            ),
          },
        ]
      : []),
    {
      field: "action",
      headerName: "Action",
      width: 40,
      renderCell: (params) => (
        <button>
          <MoreVertIcon />
        </button>
      ),
    },
  ];

  const getButtonColor = (leadStatus) => {
    switch (leadStatus) {
      case "new":
        return "rgb(5, 102, 129)";
      case "processing":
        return "rgb(58, 50, 133)";
      case "confirm":
        return "rgb(72, 110, 3)";
      case "cancel":
        return "rgb(230, 0, 35)";
      default:
        return "gray"; // Default color if status is not recognized
    }
  };

  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      full_name: d.firstName || "No",
      title: d.title || "No",
      // company: d.industry || "No",
      phone: d.phoneNumber,
      email: d.email,
      lead_status: d.leadStatus,
      lead_created: d.createdAt.slice(0, 10),
      lead_owner: d.owner,
      lead_source: d.leadSource,
      assignTo: (d.assignTo && d.assignTo.firstName) || "NO",
      isAssign: (d.assignBy && d.assignBy.firstName) || "No",
    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad">
        <Header title="Leads" subTitle={"New Lead"} openPopup={openPopup} />


<div style={{display:"flex" , gap:"30px"}}>
        <div style={{ marginTop: "-60px" }}>
          <input
            type="file"
            onChange={(e) => setFileUpload(e.target.files[0])}
          />

          <Button
            sx={{
              color: "#9B56FF",
              border: "1px solid #9B56FF",
              "&:hover": {
                backgroundColor: "#ffffff",
                color: "#9B56FF",
                border: "1px solid #9B56FF",
              },
            }}
            onClick={handleUploadCsv}
          >
            Upload CSV File
          </Button>
        </div>

        <div style={{marginTop:"-70px"}}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Lead Status</InputLabel>
      <Select
        labelId="demo-select-medium-label"
        id="demo-medium-small"
       value={status}
        label="Lead Status"
        onChange={handleDropdownOne}
       
      >
          <MenuItem value="all" >All</MenuItem>
        <MenuItem value="new" >New</MenuItem>
        <MenuItem value="processing" >In Discussion</MenuItem>
        <MenuItem value="confirm" >Hired</MenuItem>
        <MenuItem value="cancel" >Cold/Dead</MenuItem>
        <MenuItem value="hot">Hot</MenuItem>
                      <MenuItem value="hold">Hold</MenuItem>
                      <MenuItem value="negotiation">Negotiation</MenuItem>
                      <MenuItem value="lost/won">Lost/Won</MenuItem>
                      <MenuItem value="dispatch/delivered">Dispatch/Delivered</MenuItem>
                      <MenuItem value="technical">Technical Evaluation</MenuItem>
                      <MenuItem value="finincial">Finincial Evaluation</MenuItem>
                      <MenuItem value="visit">Visit Planning</MenuItem>
                      <MenuItem value="revised">Revised Quotation</MenuItem>
                      <MenuItem value="oppurtunity">Oppurtunity</MenuItem>

      </Select>
    </FormControl>
        </div>
        </div>
        <Box
          mt="10px  "
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            },
          }}
        >
          {

selectionModel.length >= 1 &&

     <Grid  mb="10px" container spacing={2}>
     <Grid item xs={12} md={4}>
       <FormControl fullWidth>
         <div>Assign To</div>
         <Select
           labelId="assign-to-select-label"
           id="assign-to-select"
           value={leadDataAssignTo}
           onChange={(e) =>
             setLeadDataAssignTo(e.target.value)
           }
         >
           {users.map((user) => (
             <MenuItem key={user._id} value={user._id}>
               <ListItemText
                 primary={`${user.firstName} ${user.lastName}`}
               />
             </MenuItem>
           ))}
         </Select>
       </FormControl>
     </Grid>

     <Grid item xs={12} md={2} sx={{ marginTop: "30px" }}>
       <Button
         variant="contained"
         onClick={handleAssignTo}
         disabled={loading}
       >
         Assign Leads
       </Button>
     </Grid>
   </Grid>
          }
     

          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={lead}
            columns={columns}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) =>
              setSelectionModel(newSelection)
            }
            
          />
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong style={{ fontSize: "20px" }}>Add Follow Up</strong>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto ",
                  width: "100%",
                }}
              >
                <FormControl sx={{ m: 1 }}>
                  <strong>Date</strong>

                  <TextField
                    type="date"
                    name="dueDate"
                    onChange={handleInputeChange}
                    value={followUP.dueDate}
                    sx={{ height: "40px" }}
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }}>
                  <strong>Time</strong>

                  <TextField
                    type="time"
                    name="time"
                    onChange={handleInputeChange}
                    value={followUP.time}
                    sx={{ height: "40px" }}
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                  <strong>Email</strong>
                  <TextField
                    type="text"
                    name="email"
                    value={followUP.email}
                    sx={{ height: "40px" }}
                  />
                </FormControl>
              </div>

              <br />
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  label="Message  "
                  type="text"
                  id="second-text-field"
                  value={followUP.description}
                  name="description"
                  onChange={handleInputeChange}
                  multiline
                  style={{ width: "100%" }}
                />
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: "2px solid #9B56FF",
                height: "37.3px",
                color: "white ",
                backgroundColor: "#9B56FF",

                "&:hover": {
                  color: "#9B56FF",
                },
              }}
              onClick={handleCreateFollowups}
            >
              Submit
            </Button>

            <Button
              sx={{
                border: "2px solid #9B56FF",
                height: "37.3px",
                color: "#9B56FF ",

                "&:hover": {
                  color: "#9B56FF",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {loading && <Loader />}

        <ToastContainer />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Leads);
