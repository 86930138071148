import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, IconButton, useTheme, Menu, MenuItem, Button } from "@mui/material";
import { useContext, useEffect, useState, } from "react";
import { ColorModeContext, tokens } from "../../theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { deleteCookie } from "../../components/Cookies";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import Badge from '@mui/material/Badge';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MobileSidebar from "./MobileSidebar";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Api from "../../Api";
import { getCookie } from "../../components/Cookies";


const Topbar = ({ role, userName }) => {
  console.log("userName", userName)
  const theme = useTheme();
  const [unreadCount, setUnreadCount] = useState(0)
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
  const [isOnNotificationsPage, setIsOnNotificationsPage] = useState(false);

  const Navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();



  useEffect(() => {
    countNotification()

    const interval = setInterval(() => {
      countNotification();
    }, 50000);

    return () => clearInterval(interval);
  }, [])



  const countNotification = async () => {

    try {
      const response = await Api.count_Notification(token)
      setUnreadCount(response.data.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const [notifications, setNotifications] = useState()


  const handleUpdate = async () => {


    try {

      const response = await Api.update_Notification(notifications, token);


      console.log("API Response:", response);


    } catch (error) {

      console.error("Error updating property:", error);
    }
  };




  const token = getCookie("leadmanagement")


  const handleNotificationClick = () => {
    setUnreadCount(0);
    setIsOnNotificationsPage(true);
    Navigate("/notifications");
  };

  // const handleNewNotification = () => {
  //   // Update state to indicate new notification
  //   setHasNotification(true);
  // };


  const backToPage = () => {
    Navigate("/notifications")
  }


  const logoutUser = () => ({
    type: 'LOGOUT_USER',

  });


  const handleLogout = () => {
    deleteCookie("leadmanagement");
    dispatch(logoutUser());
    toast.success('Logout successfully');
    return Navigate('/')
  };

  // const handleLogout = () => {
  //   setisLogging(false);
  //   localStorage.removeItem('loginAdmin');
  //   navigate("/")
  // };


  const [anchorE1l, setAnchorE1l] = useState(null);
  const [isOpen11, setIsOpen11] = useState(false);

  const clickMobileIcon = (event) => {
    setAnchorE1l(event.currentTarget);
    setIsOpen11(!isOpen11);
    setIsBackgroundBlur(true);
  }

  const handleClose1 = () => {
    setAnchorE1l(null);
    setIsOpen11(false);
    setIsBackgroundBlur(false);
  };






  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };


  const [isOpen1, setIsOpen1] = useState(false);

  const toggleSidebar = () => {
    setIsOpen1(!isOpen1);
    setIsBackgroundBlur(true);
  };

  const closeSidebar = () => {
    setIsOpen1(false);
    setIsBackgroundBlur(false);
  };




  const [isBackgroundBlur, setIsBackgroundBlur] = useState(false);


  const createLead = () => {
    Navigate("/create-lead")

  }

  const createTask = () => {
    Navigate("/create-task")

  }


  const createNote = () => {
    Navigate("/notes")

  }

  const createReminder = () => {
    Navigate("/reminders")
  }

  const createMeeting = () => {
    Navigate("/meetings")
  }

  const createUser = () => {

    Navigate(role === "admin" ? "/create-user" : role === "manager" ? "/create-agent" : "");


    // {role === "admin" ?  Navigate("/users")  :  role === "manager" ?  Navigate("/create-agent") :" "}

  }



  return (
    <>


      <Box display="flex" justifyContent="space-between" p={1} className="mobileHideSidebar" style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: "9", padding: " 20px" }} >
        {/* SEARCH BAR */}


        <Box display="flex" alignItems="center" gap="15px" >




          <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createLead}
            variant="contained"> + lead     <img style={{ height: "18px", marginLeft: "10px" }} src="/img/leads.png" alt="" /></Button>





          {role === "agent" ? "" : <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createTask}
            variant="contained"> + task     <img style={{ height: "18px", marginLeft: "10px" }} src="/img/tasks.png" alt="" /></Button>}


          {role === "agent" ? "" : <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createUser}
            variant="contained"> + user    <img style={{ height: "18px", marginLeft: "10px" }} src="/img/user.png" alt="" /></Button>}



          <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createNote}
            variant="contained"> + note     <img style={{ height: "18px", marginLeft: "10px" }} src="/img/notes.png" alt="" /></Button>




          <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createReminder}
            variant="contained"> + Reminder    <img style={{ height: "18px", marginLeft: "10px" }} src="/img/notes.png" alt="" /></Button>


          <Button sx={{
            backgroundColor: "#9B56FF", borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#9B56FF"
            }
          }}
            onClick={createMeeting}
            variant="contained"> + Meeting    <img style={{ height: "18px", marginLeft: "10px" }} src="/img/notes.png" alt="" /></Button>


        </Box>



        {/* ICONS */}
        <Box display="flex" >

          <IconButton component={NavLink} to="/notifications" onClick={handleNotificationClick}>
            <Badge
              badgeContent={unreadCount}
              color={!isOnNotificationsPage ? "error" : ""}

            >
              <NotificationsOutlinedIcon style={{ fontSize: "25px" }} onClick={handleUpdate} />

            </Badge>
            {hasUnreadNotification && (
              <div
                style={{
                  backgroundColor: "red",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
              ></div>
            )}
          </IconButton>



          <IconButton>

            <SettingsOutlinedIcon style={{ fontSize: "25px" }} />

          </IconButton>


          {/* DROPDOWN */}

          <div style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }} onClick={handleClick}>

            <div style={{ textAlign: "end", lineHeight: "12px" }}>

              <div> <strong>{userName}</strong></div>
              <div style={{ fontSize: "12px", color: "black" }} > {role === "admin" ? "admin" : role === "manager" ? "manager" : "agent"}</div>

            </div>



            <IconButton style={{ padding: "5px ", background: "ffff", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }} >
              <AccountCircleOutlinedIcon style={{ fontSize: "25px" }} />
            </IconButton>
          </div>



          <div style={{ textAlign: "center" }}>

            {/* <div style={{}}>
     <div> <strong>{userName}</strong></div>
   <div style={{fontSize:"12px" , textAlign:"end" , color:"black" }}>   {role === "admin" ? "admin" : role === "manager" ? "manager" : "agent"}</div>
     
    </div> */}









          </div>


          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem 
      
         >
          <NavLink to="" style={{textDecoration:"none" , color:"black"}}>My Profile</NavLink>
        </MenuItem>
        <MenuItem
   
         >
          <NavLink to="" style={{textDecoration:"none" ,  color:"black"}}>Settings</NavLink>
        </MenuItem> */}
            <MenuItem onClick={() => {
              handleClose();
              handleLogout();
            }}>
              Logout
            </MenuItem>
          </Menu>


          {/* {isLogging === true ? "" :
          <div onClick={handleLogout}>
            <h6 style={{ cursor: "pointer", color: "#696969" }}>Logout</h6>
          </div>} */}
          <ToastContainer />
        </Box>



      </Box>

      <Box className="mobileshowtop" style={{ display: "none", position: "sticky", top: "0", backgroundColor: "white", zIndex: "99" }}>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
            <MenuOutlinedIcon onClick={toggleSidebar} style={{ cursor: "pointer" }} />
            <img src="/img/newlogo.svg" alt="" />
          </div>

          <MoreVertOutlinedIcon onClick={clickMobileIcon} style={{ cursor: "pointer" }} />
          <Menu
            anchorEl={anchorE1l}
            open={Boolean(anchorE1l)}
            onClose={handleClose1}
          >
            {/* <MenuItem 

         >
          <NavLink to="" style={{textDecoration:"none" , color:"black"}}>My Profile</NavLink>
        </MenuItem>
        <MenuItem
 
         >
          <NavLink to="" style={{textDecoration:"none" ,  color:"black"}}>Settings</NavLink>
        </MenuItem> */}
            <MenuItem onClick={() => {
              handleClose1();
              handleLogout();
            }}>
              Logout
            </MenuItem>
          </Menu>

        </div>

        {isBackgroundBlur && <div className="background-blur" />}
      </Box>



      <Box className={`sidebar ${isOpen1 ? 'open' : ''}`}>

        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ padding: "10px 0px 10px 15px" }} >
          <img src="/img/newlogo.svg" alt="" />
          <IconButton onClick={closeSidebar}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        {/* <Button onClick={closeSidebar}>Close Sidebar</Button> */}
        <MobileSidebar />
      </Box>





    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Topbar);
