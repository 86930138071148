import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Topbar from "../global/Topbar";
import Swal from "sweetalert2";
import Api from "../../Api";
import { getCookie } from "../../components/Cookies";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;;
import CardContent from '@mui/material/CardContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Card from '@mui/material/Card';
import {DialogTitle ,Dialog ,DialogContent,DialogActions} from "@mui/material";



const counts = [
  { id: 1, count: 150, description: "Total Leads" , image:"/img/icon4.png" },
  { id: 2, count: 170, description: "Assigned Leads" , image:"/img/greenimage.png" },
  { id: 3, count: 1580, description: "Total Users" , image:"/img/redimage.png" },
  { id: 4, count: 15870, description: "Functional Head" , image:"/img/blueimage.png" },
  
  // Add more data objects as needed
];


const AgentDashboard = ({isLogging}) => {


  const openPopup = () => {
    Swal.fire({
      title: "Add Note",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        // Return the note value here
        return note;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'swal-button',
        cancelButton: 'swal-button'
      },
      buttonsStyling: false,
      padding: '2rem',
      width: '50%',
      background: '#fff',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      confirmButtonColor: '#4caf50',
      confirmButtonTextColor: '#fff',
      cancelButtonColor: '#f44336',
      cancelButtonTextColor: '#fff',
      html: `
        <style>
          .swal-button {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }
        </style>
      `
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the "Save" button is clicked
        try {
          // Your asynchronous code for saving the note
          const response = await Api.createTextNote({ text: result.value } , token);
          setIsDelete(!isDelete)
          console.log("Response from createTextNote API:", response.data.message);
          if (response.data.message === "successfully created note") {
         
            // Show success message and handle redirection
            setAllNotes(!allNotes)
            Swal.fire('Note Saved!', '', 'success');
            navigate("/admin-dashboard");
          } else {
            console.log("error");
          }
        } catch (error) {
          console.error('Error during saving note:', error);
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      }
    });
  }


  const navigate = useNavigate()
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const token = getCookie("leadmanagement")
  const [data, setData] = useState(null)
  const [isDelete, setIsDelete] = useState(false);
  const [allNotes ,setAllNotes] = useState("")
  const [notes, setNotes] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const Navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogging) {
      fetchData(); // Fetch data when the component mounts
    }
  }, [isLogging,allNotes,isDelete]);
  


  const fetchData = async () => {
    try {
      const response = await Api.getNotesByUser(token);
      if (response.data && Array.isArray(response.data.data)) {
        setNotes(response.data.data);
      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Api.deleteNote(selectedUserId , token);
      
      setIsDeleteDialogOpen(false);
      setIsDelete(!isDelete)
    } catch (error) {
     
      console.error("Error deleting user:", error);
    }
  };

  return (
   <>
        <Topbar />

        <Box m="20px" className="margin_in_mobile_ipad" >


{/* <Header title=" Manager Dashboard" subtitle="Welcome to Manager dashboard" /> */}

<Box>

<div style={{display:"grid" , gridTemplateColumns:"auto auto auto auto", gap:"20px" , }}>
{counts.map((data ,index) =>(
    <div key={data.id} style={{ display:"flex",   alignItems:"center", justifyContent: "space-between" ,  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" , padding:"10px" , borderRadius:"10px",  }}>
    <img style={{ height: "40px", width: "40px" }} src={data.image} alt="" />
    <div style={{textAlign:"end"}}  >
      <p><strong>{data.count}</strong></p>
      <p>{data.description}</p>
    </div>
  </div>
))}




</div>
</Box>

<Box >


  <div style={{display:"flex" , justifyContent:"space-between"}}> 
  
  <Typography variant="h2" gutterBottom>
        Notes
      </Typography>
  
  <Button onClick={openPopup} sx={{ backgroundColor: "#9B56FF", color: "#fff", height:"40px", ':hover': { backgroundColor: "#9B56FF", color: "#fff" } }}>Add Notes</Button>
  
  </div>
     
      <Box display="flex" flexWrap="wrap" style={{justifyContent:"space-between"}}>
        {notes.map((note, index) => (
          <Card key={index} sx={{ margin: "5px 0px" , padding:"10px" , backgroundColor:"#28c76f" , color:"white" }}>
<div style={{display:"flex" , justifyContent:"end"}}>
<CloseOutlinedIcon style={{ color: "red", fontSize: "10px" }} 
onClick={() => handleDeleteClick(note._id)}
/>
</div>

            <CardContent>
            
              <Typography variant="body2">
                {note.text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
    
  
<Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
       <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent>
         Are you sure you want to delete this Lead?
       </DialogContent>
       <DialogActions>
         <Button onClick={handleDeleteCancel} color="primary">
           Cancel
         </Button>
         <Button onClick={handleDeleteConfirm} color="error">
           Delete
         </Button>
       </DialogActions>
     </Dialog>




</Box>
   
   </>
  );
};

export default AgentDashboard;
