import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import Header from "../../components/Header";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Topbar from "../global/Topbar";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { connect } from "react-redux";
import { getCookie } from "../../components/Cookies";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const options = [
  "Save Changes",
  "Localization",
  "Payment Setting",
  "Email Setting",
  "Social Media Login",
  " Social Links",
  " SEO Setting",
  " Others",
];



const resetPassword = ["Current Password", "New Password", "Confirm Password"];



const Settings = ({ isLogging, role }) => {
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [generateKeys, setGenerateKeys] = useState();
  const token = getCookie("leadmanagement");
  const [allData, setAllData] = useState(null)






  const handleChangePasswords = async () => {
    if (!currentPassword) {
      return toast.error("please fill your current password");
    }

    if (newPassword.length < 8) {
      return toast.error(
        "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character ",
        "filled"
      );
    }

    if (newPassword !== confirmNewPassword) {
      return toast.error("password must be same");
    }

    if (!newPassword) {
      return toast.error("fill new password");
    }

    try {
      setLoading(true);
      const response = await Api.adminChangePassword(
        { currentPassword, newPassword },
        token
      );
      if (response.data.message === "Password updated successfully") {
        setChangePassword(!changePassword);
        setLoading(false);
        toast.success("Password updated successfully");
      }

      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setLoading(false);
      console.error("Error changing password:", error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {

    try {
      setLoading(true)
      const response = await Api.getAdminById(token);
      setLoading(false)
      console.log("zdfghzdfhdfghdhfhhfgjhfhgfh", response.data.data)
      setAllData(response.data.data)

    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  }




  const handleUpdateAdmin = async () => {

    try {
      setLoading(true)
      const response = await Api.updateAdmin(allData, token)
      if (response.status === 200) {
        setLoading(false)
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/setting"),
        });
        fetchData()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  useEffect(() => {
    fetchGenerateKey()
  }, [])


  const HandleGenerateKey = async () => {
    setLoading(true);
    try {
      if (role === "admin") {
        const response = await Api.generateKey(token);
        console.log(response.data.data);
        setGenerateKeys(response.data.secretKey);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const fetchGenerateKey = async () => {
    try {
      if (role === "admin") {
        const response = await Api.showGenerateKey(token);
        console.log(response.data.data);
        setGenerateKeys(response.data.data.secretKey);
      }
    } catch (error) {
      console.error("Error fetching generate key:", error);
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setAllData(() => {
      return { ...allData, [e.target.name]: e.target.value }
    })
    console.log(allData)
  }
  return (
    <>
      <Topbar />
      <Box m="30px " className="margin_in_mobile_ipad">
        <Header title="Settings" subTitle="New invoice" />

        <Box>
          <div
            className="respons1"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              width: "100%",
              gap: "10px",
            }}
          >
            {options.map((data, index) => {
              return (
                <Button
                  sx={{
                    color: "#9B56FF",
                    border: " 1px solid #9B56FF",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF",
                    },
                  }}
                >
                  {data}
                </Button>
              );
            })}
          </div>
        </Box>

        <br />

        <Box
          className="display11"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              padding: " 0px 20px",
              borderRadius: "10px",
              width: "100%",
            }}
            className=" margininvoice"
          >

            {role === "admin" ? <>


             

              <TextField
                type='text'
                value={generateKeys}
                style={{ marginRight: "10px", marginTop: "10px", width: "50%" }}
                InputProps={{ readOnly: true }}
              /> 

{generateKeys && generateKeys.length > 0 ? "":
              <>
              <Button
                sx={{
                  marginTop: "10px",
                  backgroundColor: "#9B56FF",
                  color: "white",
                  '&:hover': {
                    backgroundColor: '#ffffff',
                    color: "#9B56FF",
                    border: "1px solid #9B56FF"
                  },
                  height: "40px"
                }}
                onClick={HandleGenerateKey}
              >
                Generate Key
              </Button>
              
              </>
              
              
              }
              
                 

            </> : ""}


            {role === "admin" && (
                <>
                  {resetPassword.map((data, index) => (
                    <div key={index}>
                      <div>
                        <p
                          style={{
                            fontSize: "17px",
                            padding: "0px 5px 0px 0px",
                          }}
                        >
                          {data}
                        </p>
                      </div>
                      <TextField
                        fullWidth
                        id="fullWidth"
                        type={
                          data.toLowerCase().includes("password")
                            ? "password"
                            : "text"
                        }
                        value={
                          data === "Current Password"
                            ? currentPassword
                            : data === "New Password"
                              ? newPassword
                              : confirmNewPassword
                        }
                        onChange={(e) => {
                          if (data === "Current Password")
                            setCurrentPassword(e.target.value);
                          if (data === "New Password")
                            setNewPassword(e.target.value);
                          if (data === "Confirm Password")
                            setConfirmNewPassword(e.target.value);
                        }}
                      />
                    </div>
                  ))}
                  <br />
                  <div style={{ display: "flex " }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: "1px solid #9B56FF",
                          },
                        }}
                        onClick={handleChangePasswords}
                      >
                        Change Password
                      </Button>
                      <Button
                        sx={{
                          color: "#9B56FF",
                          border: "1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: "1px solid #9B56FF",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            <div >
              <p style={{ fontSize: "20px" }}>Website Basic Details</p>
              <hr />
              {allData && allData != null ?
                <>
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    WebSite Name
                  </p>
                  <TextField
                    fullWidth
                    value={allData.website}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, website: inputText });
                    }}

                  />
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Logo
                  </p>
                  <TextField
                    fullWidth
                    value={allData.logo || "no"}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, logo: inputText });
                    }}
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Favicon
                  </p>
                  <TextField
                    fullWidth
                    value={allData.favicon}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, favicon: inputText });
                    }}
                  />
                  <br />

                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
                      <Button
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={handleUpdateAdmin}
                      >
                        Update
                      </Button>

                      <Button
                        sx={{
                      
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>



                </>

                : "no data found"

              }

              <br />

      
            </div>
          </Box>

          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              padding: " 0px 20px",
              borderRadius: "10px",
              width: "100%",
            }}
            className=" margininvoice"
          >
            <div style={{}}>
              <p style={{ fontSize: "20px" }}>Address Details</p>
              <hr />
              {allData && allData !== null ?
                <>
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Country
                  </p>
                  <TextField
                    fullWidth
                    value={allData.country}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, country: inputText });
                    }}
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    State
                  </p>
                  <TextField
                    fullWidth
                    value={allData.state}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, state: inputText });
                    }}
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    City
                  </p>
                  <TextField
                    fullWidth
                    value={allData.city}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, city: inputText });
                    }}

                  />


                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Postal Code
                  </p>
                  <TextField
                    fullWidth
                    value={allData.zipCode}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, zipCode: inputText });
                    }}

                  />



                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Street
                  </p>
                  <TextField
                    fullWidth
                    value={allData.street}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, street: inputText });
                    }}

                  />



                  <br />



                  <div style={{ display: "flex", margin: "10px" }}>
                    <div style={{ display: "flex", gap: "10px", }}>
                      <Button
                        onClick={handleUpdateAdmin}
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Update
                      </Button>

                      <Button
                        sx={{
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>




                </> : "no data found"


              }





              <br />
            </div>{" "}
          </Box>
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this user?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            {/* <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button> */}
          </DialogActions>
        </Dialog>

        {/* Success Snackbar */}
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="User deleted successfully"
        />
      </Box>
      {loading && <Loader />}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Settings);
