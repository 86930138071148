import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import Api from '../../Api';
import { useNavigate } from "react-router-dom";
import {  IconButton, Typography } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];


const InvoicesDetails = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [taskData, setTaskData] = useState([])



//   useEffect(() => {
//     fetchData();
//   }, []);



//   const fetchData = async () => { 
//     try { 
     
//       const response = await Api.get_All_Task();
//     if (response.data && Array.isArray(response.data.data)) {
//     //  console.log(response.data.data)
//     setTaskData(mapUserData(response.data.data));
     
//     console.log(response.data.data)
//     } else {
//       console.error('Data is not an array or is missing:', response.data.data);
//     }
//     } catch (error) {
//       console.error('Error fetching data:', error);
    
//     }
//   };










  // useEffect(() => {
  //   !isLogging && navigate('/')
  // }, [])

  const columns = [
    { field: 'sno', headerName: 'S.No', width:40 ,  },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'task_name', headerName: 'Task Name', width: 120 },
    { field: 'percent_complete_indicator', headerName: 'Percent Complete Indicator', width: 170 },
    { field: 'responsible_user', headerName: 'Responsible User', width: 170  },
    { field: 'due_date', headerName: 'Due Date', width: 170 },

    {
      field: 'task_owner',
      headerName: 'Task Owner',
       width: 170

   
    },
    {
      field: 'status',
      headerName: 'Status'
      , width: 170,
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="error" style={{
          backgroundColor: params.row.status =="Started" ? "#0DCEB0" : "#FFD505"
        
        }}
 
        >
      {params.row.status}
        </Button>
      ),
    }

    ,

    {
      field: ' ',
      headerName: ' '
      , width: 20,
  
     
      headerName: ' ',
      flex: 1,
      width: 100,

    },
    {
      field: 'blank',
      headerName: '',
       width: 20 ,
      renderCell: (params) => (
        <img src="/img/star.png" alt="" />
      ),
    }

    ,
    {
      field: 'action',
      headerName: 'Action',
       width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    }
  
  ];

  // useEffect(() => {
  //   const staticData = [
  //     { id: 1, sno: 1, task_name: 'Task 1', percent_complete_indicator: '50%', responsible_user: 'User 1', due_date: "12/32/3939", task_owner: "kareena" },
  //     { id: 2, sno: 2, task_name: 'Task 2', percent_complete_indicator: '30%', responsible_user: 'User 2', due_date: "12/32/0939", task_owner: "yash" },
  //     // Add more tasks as needed
  //   ];
  //   setUserData(staticData);
  //   // fetch(`${BACKEND_URL}/admin/users-tenent`)
  //   //   .then((response) => response.json())
  //   //   .then((data) => {
  //   //     setUserData(mapUserData(data.data))
  //   //   })
  //   //   .catch((error) => console.error('Error fetching user data:', error));
  // }, []
  //   //  [isSnackbarOpen]
  // );

  const mapUserData = (data) => {
  
    return  data.map((d, index) => ({
      
      sno: index + 1,
      id: d._id || index,
      // login: user.login,
      task_name: d.tName || 'No',
      percent_complete_indicator: d.priority || 'No',
      responsible_user: d.responsible ||  'No',
      status:d.status ||"No",
      due_date:d.dueDate.slice(0,10) || "No",
      task_owner:d.taskOwner || "No",
      
    }));
  };




  // const handleDeleteConfirm = async () => {
  //   try {
  //     await axios.get(`${BACKEND_URL}/admin/delete-user/${selectedUserId}`);
  //     setIsSnackbarOpen(true);
  //     setIsDeleteDialogOpen(false);
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };


  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Box  >
      {/* <Header title="Tasks" subTitle="New Task"  /> */}
      <Box style={{boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", padding:"20px" , borderRadius:"10px" , backgroundColor:"#FFFFFF"}}
        m="40px 100px 40px 100px "
      className=" margininvoice"
      
      >


   


            <div style={{  display:"flex", justifyContent:"space-between" ,   }} className='invoicesalign'>
        
          <div>        <img src="/img/newlogo.svg" alt="" />
          <Typography variant="h2" >INVOICE</Typography>
          <p style={{fontSize:"15px" }}>Invoice Number : In983248782</p>
          </div>
                
                  
                  



         
          <div style={{textAlign:"right"}} className='align'> 
                   <p style={{fontSize:"20px" }}>Invoice From</p>
                   <p style={{fontSize:"20px" }}>Company Name</p>
                   <p style={{fontSize:"15px" }}>9087484288</p>
                   
                   <p style={{fontSize:"15px" }}>Address line 1, Address line 2</p>
                   <p style={{fontSize:"15px" }}>Zip code ,City - Country</p>


                   
                   </div>
                   
                  
               
                
                </div>

                <hr />



                <div style={{  display:"flex", justifyContent:"space-between" ,   } }     className='invoicesalign'  > 
        
        <div>       
        <p style={{fontSize:"20px" }}>Billed to</p>
        <p style={{fontSize:"20px" }}>Customer Name</p>


        <p style={{fontSize:"15px" }}>9087484288</p>
        <p style={{fontSize:"15px" }}>Address line 1,</p>
        <p style={{fontSize:"15px" }}>Address line 2</p>
        <p style={{fontSize:"15px" }}>Zip code ,City - Country</p>

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"20px" }}>Payment Details</p>
                
                 <p style={{fontSize:"15px" }}>Debit Card</p>
                 
                 <p style={{fontSize:"15px" }}>XXXXXXXXXXXX-2541</p>
                 <p style={{fontSize:"15px" }}>HDFC Bank</p>

                 <div style={{border:"2px solid transparent ", padding:"0px 10px 0px 20px" ,textAlign:"right" ,backgroundColor:"#F5F7FF" , borderRadius:"10px" ,width:"200px" }} className='align'  ><p style={{fontSize:"15px" }}>Recurring : 15 Months</p><p style={{fontSize:"15px" }}>PO Number : 54515454</p> </div>
                 
                 </div>
                 
                
             
              
              </div>


              


              <div style={{  display:"flex", justifyContent:"space-between" , backgroundColor:"#9A55FF" , padding:"0px 40px 0px 40px" , borderRadius:"10px"  , color:"white"  }}    className='invoicesalign1'>
        
        <div>       
        <p style={{fontSize:"20px" }}>Issue Date : 27 Jul 2022</p>
       

        </div>
              
                
                



       
        <div > 
                 <p style={{fontSize:"20px" }}>Due Date : 27 Aug 2022</p>
                
                
                 </div>
                 
                 <div > 
                 <p style={{fontSize:"20px" }}>Due Amount : ₹ 1,54,22</p>
                
                
                 </div>
             
              
              </div>



              <br/>






              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>  <p style={{fontSize:"20px" }}>Description</p></TableCell>
            <TableCell >  <p style={{fontSize:"20px" }}>Category</p></TableCell>
            <TableCell >  <p style={{fontSize:"20px" }}>Rate/Item</p></TableCell>
            <TableCell >  <p style={{fontSize:"20px" }}>Quantity</p></TableCell>
            <TableCell >  <p style={{fontSize:"20px" }}>Discount (%)</p></TableCell>
            <TableCell  >  <p style={{fontSize:"20px" ,textAlign:"right"  }}>Amount</p></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >
           
                <p style={{fontSize:"15px" }}>     {row.name}</p>
              </TableCell>
              <TableCell >   <p style={{fontSize:"15px" }}>{row.fat}</p></TableCell>
              <TableCell >  <p style={{fontSize:"15px" }}>     {row.name}</p></TableCell>
              <TableCell >  <p style={{fontSize:"15px" }}>     {row.name}</p></TableCell>
              <TableCell >  <p style={{fontSize:"15px" }}>     {row.name}</p></TableCell>
              <TableCell >  <p style={{fontSize:"15px" ,textAlign:"right"  }}>     {row.name}</p></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <br/>


    <div style={{  display:"flex", justifyContent:"space-between" ,   } }     className='invoicesalign'  > 
        
        <div>       
        <p style={{fontSize:"20px" }}>Notes:</p>
        


        <p style={{fontSize:"15px" }}>Enter customer notes or any other details</p>




        <p style={{fontSize:"20px" }}>Terms and Conditions:</p>
        <p style={{fontSize:"15px" }}>Enter customer notes or any other details</p>
   

        </div>
              
                
                



       
      <div style={{border:"1px solid grey " , padding:"0px 20px 0px 20px" , width:"50%" , borderRadius:"10px" }} className='js11' >
      <div style={{  display:"flex", justifyContent:"space-between" ,   } }     > 
        
        <div>       
        <p style={{fontSize:"15px" }}>Taxable</p>
       

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"15px" }}>$6,660.00</p>
                
                 
                 </div>
                 
                
             
              
              </div>

              <div style={{  display:"flex", justifyContent:"space-between" ,   } }       > 
        
        <div>       
        <p style={{fontSize:"15px" }}>Additional Charges</p>
       

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"15px" }}>$6,660.00</p>
                
                 
                 </div>
                 
                
             
              
              </div>

              <div style={{  display:"flex", justifyContent:"space-between" ,   } }       > 
        
        <div>       
        <p style={{fontSize:"15px" }}>Discount</p>
       

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"15px" }}>$3,300.00</p>
                
                 
                 </div>
                 
                
             
              
              </div>

              <div style={{  display:"flex", justifyContent:"space-between" ,   } }       > 
        
        <div>       
        <p style={{fontSize:"15px" }}>Sub total</p>
       

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"15px" }}>$3,300.00</p>
                
                 
                 </div>
                 
                
             
              
              </div>
              <hr/>


              <div style={{  display:"flex", justifyContent:"space-between" ,   } }       > 
        
        <div>       
        <p style={{fontSize:"15px" }}>Total Amount</p>
       

        </div>
              
                
                



       
        <div style={{textAlign:"right"}}    className='align'> 
                 <p style={{fontSize:"15px" }}>$143,300.00</p>
                
                 
                 </div>
                 
                
             
              
              </div>


            
      </div>
                 
                
             
              
              </div>






              <br/>


  
<p style={{textAlign:"right"}}>Signature</p>
<p style={{textAlign:"right"}}>Name</p>










              
              

                


      


        
        {/* <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={ taskData} columns={columns} /> */}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="User deleted successfully" />
    </Box>
  );
};

export default InvoicesDetails;
