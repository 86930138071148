import { Typography, Box, useTheme, } from "@mui/material";
import { tokens } from "../theme";
import { Suspense, useState } from "react";
import { Select, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
const Header = ({ title, subTitle , handleDeleteData , handleSaveData ,setShowPopup ,showPopup ,openPopup }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const [selectedOption, setSelectedOption] = useState("Option 1"); 
  const [optionChange, setOptionChange] = useState("All Tasks");
  const [contact, setContact] = useState("All Contacts")
  const [company, setCompany] = useState("All Companies")
  const [lead, setLead] = useState("All Leads")
  const [deal, setDeal] = useState("All Deals")
  const [project, setProject] = useState("All Projects")
  const [report, setReport] = useState("All Reports")



  const handleChange = (event) => {
    setSelectedOption(event.target.value); 
  };

  const handleOptionChange = (event) => {
    setOptionChange(event.target.value); 
  };


  const handleContactChange = (event) => {
    setContact(event.target.value); 
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value); 
  };

  const handleLeadChange = (event) => {
    setLead(event.target.value); 
  };

  const handleDealChange = (event) => {
    setDeal(event.target.value); 
  };

  const handleProjectChange = (event) => {
    setProject(event.target.value); 
  };


  const handleReportChange = (event) => {
    setReport(event.target.value); 
  };


 
  const options = ["Option 1", "Option 2", "Option 3"];

  const taskOption = ["All Tasks", "My Task", "My Open Task", "My Completed Task", "All Completed Task"]

  const contactOption = ["All Contacts", "Next Contact", "Privious Contact", "Last 7 days", "Last Month"]

  const companyOptions = ["All Companies", "Recently Viewed", "Next Company", "Privious Company", "Added Company", "Removed Company"]

  const leadOptions = ["All Leads", "All Closed Lead", "All Open Lead", "Converted", "Today Lead"]

  const dealOption = ["All Deals", "All Closed Deal", "All Open Deals", "Today Deal", "Removed Deal"]

  const projectOption = ["All Projects", "All Closed Projects", "All Open Projects", "Today Projects", "Removed Projects"]
 
 
  const reportOption = [ 
    // "My Personal Reports" , "Shared Reports" ,
   "All Reports" 
  //  , "Task Reports" 
   ,
  "Contact Reports" , "Company Reports" , "Lead Reports" 
  // , "Deal Reports"
   , "Project Reports " 
  //  , "Other Reports"
  ] 
 
 
  return (

    <>
      <Box mb="30px" sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" 
        , padding: "12px",
        borderRadius: "10px",
        justifyContent: "space-between",
     
      }}  
      className="columnInvoice mobileHideSidebar"

      >




        {
          title === "Back to Invoice List" ?
          <div style={{display:"flex" , alignItems:"center", gap:"10px" }}>  < img style={{ height: "25px", width: "25px", marginBottom: "4px" }} src="/img/Frame1.png" alt="" />   
          <Typography

variant="h4"
color={colors.grey[100]}
fontWeight="bold"
sx={{ m: "0 0 5px 0" }}
>
{title}

</Typography>
          </div>
            :

            <div style={{display:"flex" , alignItems:"center", gap:"10px" }}>              <img style={{ height: "40px", width: "40px" }} src="/img/icon4.png" alt="" />
   
            <Typography
  
  variant="h4"
  color={colors.grey[100]}
  fontWeight="bold"
  sx={{ m: "0 0 5px 0" }}
  >
  {title}
  
  </Typography>
            </div>

        }







        



        {
          title === "Back to Invoice List" ? <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}> <VisibilityIcon sx={{ color: "#9B56FF", fontSize: "18px", marginTop: "19px" }} onClick={()=>{
            setShowPopup(!showPopup)
          }} /><h4 style={{ color: "#9B56FF" ,cursor:"pointer" }} onClick={()=>{setShowPopup(!showPopup)}}>Preview</h4>
           <Button
            style={{ backgroundColor: "#6C757D", color: "white", height: "100%", marginTop: "10px" }} onClick={handleDeleteData}>Delete Invoice</Button><Button
              style={{ backgroundColor: "#9B56FF", color: "white", height: "100%", marginTop: "10px" }} onClick={handleSaveData}>Save Draft</Button></div> : ""

        } 


       
      </Box>






 { title === " Deal Dashboard"  || title === " Project Dashboard" || title === " Leads Dashboard" ? " " : <Box mb="30px" sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: "10px", padding: " 0px 0px  0px 0px" }}  >




{/* {subTitle === "" ? <div></div>
  : subTitle === "New Task" ?
    <Select value={optionChange} onChange={handleOptionChange}    >

      {taskOption.map(task => (
        <MenuItem key={task} value={task}>{task}</MenuItem>
      ))}
    </Select>
    : subTitle === "New Contacts" ?
      <Select value={contact} onChange={handleContactChange}    >

        {contactOption.map(contact => (
          <MenuItem key={contact} value={contact}>{contact}</MenuItem>
        ))}
      </Select>
      : subTitle === "New Company" ?
        <Select value={company} onChange={handleCompanyChange}    >

          {companyOptions.map(company => (
            <MenuItem key={company} value={company}>{company}</MenuItem>
          ))}
        </Select>

         
          : subTitle === "New Deal" ?
            <Select value={deal} onChange={handleDealChange}    >

              {dealOption.map(deals => (
                <MenuItem key={deals} value={deals}>{deals}</MenuItem>
              ))}
            </Select> :
            subTitle === "New Projects" ?
              <Select value={project} onChange={handleProjectChange}       >

                {projectOption.map(projects => (
                  <MenuItem key={projects} value={projects}>{projects}</MenuItem>
                ))}
              </Select> :


subTitle === "New Report" ?
<Select value={report} onChange={handleReportChange}       >

  {reportOption.map(reports => (
    <MenuItem key={reports} value={reports}>{reports}</MenuItem>
  ))}
</Select> :



              <div></div>
            
              } */}




{/* {title === "Tasks" ?  
<Select value={selectedOption} onChange={handleChange}    >

    {taskOption.map(task => (
      <MenuItem key={task} value={task}>{task}</MenuItem>
    ))}
  </Select> :""} */}






 <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}  >



{subTitle === ""   ? <div>
  <img style={{ height: "20px", width: "20px", marginRight: "20px" }} src="/img/line3.png" alt="" />
  <img style={{ height: "20px", width: "20px" }} src="/img/icon4 2.png" alt="" />

</div>
  :
  <img style={{ height: "30px", width: "30px" }} src="/img/icon4 2.png" alt="" />}


{
  subTitle === ""  ? "" :
    <Button sx={{
      backgroundColor: "#9B56FF", borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#9B56FF"
      }
    }}   onClick={openPopup}    variant="contained">{subTitle}</Button>

}

</Box> 




</Box> } 





    </>



  );
};

export default Header;