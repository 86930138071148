import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import Loader from "../Loader";
import { NavLink } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { useParams } from "react-router-dom";
import { Grid, TextField, Select, MenuItem} from '@mui/material';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const Tasks = ({isLogging , role}) => {



  const { id } = useParams();

  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);





const openPopup = () =>{
  navigate("/create-task");

}


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
const [task ,setTask] =useState([])
const [loading , setLoading] = useState(false)
const [selectedUserId, setSelectedUserId] = useState(null);
const [isDelete, setIsDelete] = useState(false);
const token = getCookie("leadmanagement")

useEffect(()=>{
fetchData()
},[isDelete])
  

const fetchData =async ()=>{

  try {
    setLoading(true)
    let response;
    if (role === 'admin') {
      response = await Api.get_All_Task(token);
    } else if (role === 'manager') {
      response = await Api.get_Manager_Task(token);
    } else if (role === 'agent') {
      response = await Api.get_Agent_Task(token);
    }
    
    if(response.data && Array.isArray(response.data.data)){
      setLoading(false)
      setTask(mapPlotData(response.data.data));
      // setLead(response.data.data)
      console.log(response.data.data);
      
    }else{
      console.error('Data is not an array or is missing:', response.data.data);
    }
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
}
  

  useEffect(()=>{
    !isLogging && navigate('/')
  },[])



  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => { 
    try {
      setLoading(true)

      const response = await Api.delete_task( selectedUserId , token  );
      setLoading(false)

      console.log( "api response",response.data)
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };




  const handleTaskStatusChange = async (event, id) => {
    const newStatus = event.target.value;
    console.log( "newstats",newStatus);
    console.log( " event id",id);

    try {
      setLoading(true)
    const response =  await Api.updateTask_Status(id, { status: newStatus }, token);
    console.log( "api response",response);

      const updatedTask = task.map((item) =>
        item.id === id ? { ...item, status: newStatus } : item
      );
      setTask(updatedTask);

      if(response.data.message == "task updated successfully") {
        toast.success("Task Status updated successfully")
        setLoading(false)

      }
    } catch (error) {
      setLoading(false)
      console.error("Error updating lead status:", error);
    }
  };



  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "subject", headerName: "Subject", width: 120 ,

    renderCell: (params) => (
      <Link to={`/task-details/${params.row.id}`}>
        {params.value}
        </Link>
    ),
  
  },


    { field: 'priority', headerName: 'Priority ', width: 120 },


    // {
    //   field: 'lead_status',
    //   headerName: 'Lead Status',
    //   width: 170,
    //   renderCell: (params) => (
    //     <Button style={{ backgroundColor: getButtonColor(params.value) , padding:" 5px 10px" , width:"120px", color:"white"  }}>
    //    {params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase()}
    //     </Button>
    //   ),
    // },
    { field: 'createdAt', headerName: 'Start Date', width: 120  },
    { field: 'dueDate', headerName: 'Due Date', width: 120  },
    { field: 'isAssign', headerName: 'Assigned By', width: 120  },
    { field: 'assignTo', headerName: 'Assigned To ', width: 120  },
    

    // {
    //   field: 'follow_up',
    //   headerName: 'Add Followup'
    //   , width: 120 ,
    //   renderCell: (params) => (
    //     <Link to={`/google-calendar/${params.row.id}`}> 
    //     <AddCircleOutlineOutlinedIcon />
    //   </Link>
    //   ),

    
    // },


    // { field: 'status', headerName: 'Status', width: 120 },

    {
      field: "status",
      headerName: "Status",
      width: 170,

      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) => handleTaskStatusChange(e, params.row.id)}
          style={{
            backgroundColor: getButtonColor(params.value),
            padding: "2px ",
            width: "130px",
            color: "white",
            height:"35px"
            
          }}
        >
         <MenuItem value="new">New</MenuItem>
      <MenuItem value="processing">In discussion</MenuItem>
      <MenuItem value="confirm">Hired</MenuItem>
      <MenuItem value="cancel">Cold/Dead</MenuItem>
        </Select>
      ),
    },


    
    {
      field: 'edit_task',
      headerName: 'Edit Task'
      , width: 100 ,
      renderCell: (params) => (
        <Link to={`/edit-task/${params.row.id}`}>
          <EditIcon />
        </Link>
      ),

    
    }

    ,  {
      field: 'delete_task',
      headerName: 'Delete Task'
      , width: 100 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }

    ,

    ,{
      field: 'action',
      headerName: 'Action',
       width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },





  ];


  const getButtonColor = (leadStatus) => {
    switch (leadStatus) {
   
      case 'new':
        return 'rgb(5, 102, 129)';
      case 'processing':
        return 'rgb(58, 50, 133)';
      case 'confirm':
        return 'rgb(72, 110, 3)';
      case 'cancel':
        return 'rgb(230, 0, 35)';
      default:
        return 'gray'; // Default color if status is not recognized
    }
  };




  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id ||index  ,
      subject: d.subject || "No",
      priority: d.priority || "No",
      status: d.status,
      createdAt:d.createdAt.slice(0,10),  
      dueDate:d.dueDate,
      assignTo:d.assignTo && d.assignTo.firstName || "NO",
      isAssign:d.assignBy &&  d.assignBy.firstName  || "No",
      
    

    }));
  };

  return (
  <>
       <Topbar />

       <Box m="20px" className="margin_in_mobile_ipad" >
 
      
 <Header title="Tasks" subTitle="New Task" openPopup={openPopup} />
 <Box
   m="20px 0 0 0"
   height="75vh"
   sx={{
     "& .MuiDataGrid-root": {
   
       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
       borderRadius:"10px"
     },
 
   }}
 >
   <DataGrid
     components={{ Toolbar: GridToolbar }}
    //  checkboxSelection
     rows={task}
     // rows={plotData}

     columns={columns}
   />
 </Box>


     {/* Delete Confirmation Dialog */}
     <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
       <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent>
         Are you sure you want to delete this Lead?
       </DialogContent>
       <DialogActions>
         <Button onClick={handleDeleteCancel} color="primary">
           Cancel
         </Button>
         <Button onClick={handleDeleteConfirm} color="error">
           Delete
         </Button>
       </DialogActions>
     </Dialog>
     {loading && <Loader />}

     <ToastContainer/>



     </Box>
  </>
  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});


export default  connect(mapStateToProps)(Tasks);
