import React, { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate, } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { connect } from "react-redux";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LeadsDetails = ({ role, userName }) => {
  const token = getCookie("leadmanagement")
  const { id } = useParams();
  const navigate = useNavigate()

  const [leadData, setLeadData] = useState(null)

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {

    try {
      const response = await Api.get_LeadsBy_Id(id);
      console.log(response.data.data)
      setLeadData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



  const backToPage = () => {
    navigate("/leads")
  }


  // State to store fetched users
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === 'admin') {
          response = await Api.getAllUsers(token);
        } else if (role === 'manager') {
          response = await Api.getAllManagerAgent(token);
        } else if (role === 'agent') {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [role])









  return (
    <>


      <Topbar />
      {
        leadData && (
          <>

            <Box m="20px" className="margin_in_mobile_ipad"


            >
              <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 20px", alignItems: "center", backgroundColor: "white", zIndex: "9999999", borderRadius: "10px" }}>

                <div style={{ display: "flex", padding: "10px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>



                    <Button sx={{
                      backgroundColor: "#9B56FF", color: "white", '&:hover': {
                        backgroundColor: '#ffffff',
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF"
                      }
                    }}

                      onClick={backToPage}> Cancel</Button>

                  </div>
                </div>

              </div>

              <hr />


              <div style={{ padding: " 10px", alignItems: "center" }}>

                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%",


                  }}  >

                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Lead Information

                    </Typography>

                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto   "
                      }}
                      noValidate
                      autoComplete="off"
                    >


                      <h3>
                        {" "}
                        <strong>First Name :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.firstName || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong> Last Name :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.lastName || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>Email :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.email || "No"}
                        </span>
                      </h3>




                      <h3>
                        {" "}
                        <strong>Phone :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.phoneNumber || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>alternate Number :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.alternateNumber || "No"}
                        </span>
                      </h3>




                    </Box>
                    {
                      leadData.additionalInformation && leadData.additionalInformation.length > 0 &&

                      leadData.additionalInformation.map((data, index) => (

                        <>
                          <Typography

                            variant="h4"

                            fontWeight="550"
                            sx={{ m: "0 0 5px 0", }}

                            className="heading_animation"
                          >
                            Additional Information{index + 1}

                          </Typography>


                          <Box
                            component="form" className="LMS-Info-section"
                            sx={{
                              gridTemplateColumns: "auto auto   "
                            }}
                            noValidate
                            autoComplete="off"
                          >


                            <h3>
                              {" "}
                              <strong>First Name :</strong>{" "}
                              <span style={{ fontWeight: "600" }}>
                                {data.firstName || "No"}
                              </span>
                            </h3>


                            <h3>
                              {" "}
                              <strong> Last Name :</strong>{" "}
                              <span style={{ fontWeight: "600" }}>
                                {data.lastName || "No"}
                              </span>
                            </h3>

                            <h3>
                              {" "}
                              <strong>Email :</strong>{" "}
                              <span style={{ fontWeight: "600" }}>
                                {data.email || "No"}
                              </span>
                            </h3>




                            <h3>
                              {" "}
                              <strong>Phone :</strong>{" "}
                              <span style={{ fontWeight: "600" }}>
                                {data.phone || "No"}
                              </span>
                            </h3>






                          </Box>





                        </>



                      ))

                    }






                  </Box>








                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%"


                  }} >
                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Address Information

                    </Typography>





                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto  "
                      }}
                      noValidate
                      autoComplete="off">

                      <h3>
                        {" "}
                        <strong>Street :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.street || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong>City :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.city || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>Postal Code :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.zipCode || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>State :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.state || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>Country :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.country || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong>Project Code :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.projectCode || "No"}
                        </span>
                      </h3>

                    </Box>

                  </Box>

                </div>
                <br />
                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%"


                  }}   >
                    <Typography


                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Company Information

                    </Typography>


                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto  "
                      }}
                      noValidate
                      autoComplete="off">


                      <h3>
                        {" "}
                        <strong>Company Name :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.industry || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong> Owner :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.owner || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong> WebSite :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.website || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong> Number of Employees :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.employNumber || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong> Lead Source :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.leadSource || "No"}
                        </span>
                      </h3>

                    </Box>
                  </Box>

                  <Box sx={{

                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%"


                  }}    >
                    <Typography

                      variant="h4"

                      fontWeight="550"

                      sx={{ m: "0 0 5px 0", }}
                      className="heading_animation"
                    >
                      Additional Information

                    </Typography>


                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto   "
                      }}
                      noValidate
                      autoComplete="off">

                      <h3>
                        {" "}
                        <strong> Lead Status :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.leadStatus || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong> Lead Source :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.leadSource || "No"}
                        </span>
                      </h3>

                 

                      <h3>
                        {" "}
                        <strong>User Responsible :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData?.assignTo?.firstName && leadData?.assignTo?.lastName
                            ? `${leadData.assignTo.firstName} ${leadData.assignTo.lastName}`
                            : "No"}
                        </span>
                      </h3>



                    </Box>
                  </Box>

                </div>

                <br />


                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>


                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%"


                  }} >


                    <h3>
                      {" "}
                      <strong> Title :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.title || "No"}
                      </span>
                    </h3>


                    {leadData.notes && leadData.notes.length > 0 &&
                      leadData.notes.map((data, index) => (
                        <>
    <h3>
                      {" "}
                      <strong> Notes {index +1} :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {data.text || "No"}
                      </span>
                    </h3>


                    <h3>
                      {" "}
                      <strong> Date {index +1} :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {data.date || "No"}
                      </span>
                    </h3>


                        </>
                      ))
                    }


                  </Box>




                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "10px"
                    , width: "100%"


                  }}>
                    <Box
                      component="form"

                      noValidate
                      autoComplete="off"
                    >
                      <h3>
                        {" "}
                        <strong> Description :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: leadData.description || "No",
                          }}
                        />
                        </span>
                      </h3>





                    </Box>
                  
                    </Box>

                  
                </div>

                <Box sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
borderRadius: "10px"
, padding: "10px"
, width: "100%"


}}>
<Box
  component="form"

  noValidate
  autoComplete="off"
>


<h2> <strong> FAQ :</strong>{" "}</h2>


  {leadData.faq && leadData.faq.length > 0 && 
  leadData.faq.map((data ,index)=>(
<>

<div >



<h3>
    {" "}
    <strong> Question {index+1} :</strong>{" "}
    <span style={{ fontWeight: "600" }}>
      {data.question || "No"}
    </span>
  </h3>

  <h3>
    {" "}
    <strong> Answer {index+1} :</strong>{" "}
    <span style={{ fontWeight: "600" }}>
      {data.answer || "No"}
    </span>
  </h3>


</div>

</>

  ))}


<h2> <strong> Pricing :</strong>{" "}</h2>

{leadData.pricing && leadData.pricing.length > 0 && 
  leadData.pricing.map((data ,index)=>(
<>

<div >



<h3>
    {" "}
    <strong> Amount {index+1} :</strong>{" "}
    <span style={{ fontWeight: "600" }}>
      {data.amount || "No"}
    </span>
  </h3>

  <h3>
    {" "}
    <strong> Payment {index+1} :</strong>{" "}
    <span style={{ fontWeight: "600" }}>
      {data.payment || "No"}
    </span>
  </h3>


</div>

</>

  ))}
 




</Box>



</Box>





































































              </div>
              <ToastContainer />
            </Box>

          </>)
      }

    </>

  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(LeadsDetails);
