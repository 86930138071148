import React from "react";
import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { connect } from "react-redux";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotesIcon from '@mui/icons-material/Notes';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

const DropdownItem = ({ title, to, icon, selected, setSelected, children }) => {
  console.log()
  console.log(children)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setSelected(title)
    setOpen(!open);
  };


  return (
    <>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],

        }}

        icon={icon}
        onClick={handleToggle}

      >

        <Typography style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>{title} <ExpandMoreIcon /></Typography>

      </MenuItem>


      {open && children && children.map((child, index) => (
        <MenuItem
          key={index}
          active={selected === child.title}
          style={{
            color: colors.grey[100],
          }}
          onClick={() => setSelected(child.title)}

        >
          <Typography style={{ padding: "10px 0px 0px 40px" }}>{child.title}</Typography>
          <Link to={child.to} />
        </MenuItem>
      ))}
    </>
  );
};

const Item = ({ title, to, icon, selected, setSelected, childItem }) => {

  console.log(childItem)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (childItem && childItem.length > 0) {
    return (





      DropdownItem({
        title: title,
        to: to,
        icon: icon,
        selected: selected,
        setSelected: setSelected,
        children: childItem
      })


    );
  } else {


    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >

        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  }
};



const Sidebar = ({ role }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [selected, setSelected] = useState("Dashboard ");









  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);








  return (
    <Box



      sx={{
       
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: isCollapsed ? "5px 20px !important" : "5px 10px !important"
        },
      
        "& .pro-menu-item.active": {
          backgroundColor: "#9B56FF !important",
          color: "white !important "
        },

        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" 
        ,
        paddingBottom: "50px"

      }}
      className='yash mobileHideSidebar'
    >
      { role ==="admin"  ? 
      
        ( <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
        
          <div className="logo-item">
            <div

              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
           
                color: colors.grey[100],
              }}
            >
              {!isCollapsed ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                  <img src="/img/newlogo.svg" alt=""  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              ) : (


                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </div>


          <br />


          <Box paddingLeft={isCollapsed ? undefined : "2%"} >

            <Item
              title="Dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
            >

            </Item>
           
            <Item
              title="Leads"
              to="/leads"
              icon={<LeaderboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Confirm Lead"
              to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />



<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Reminders"
            to="/reminders"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Meetings"
            to="/meetings"
              icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />


 <Item
              title="Invoices"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
              childItem={[{ title: "Invoices List", to: "/invoices-list" },
              ]}
            />



            
<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title=" Users"
              to="/users"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title=" Agent"
              to="/admin/agent"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />



            


            <Item
              title="Settings"
              to="/setting"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

          </Box>
        </Menu>
      </ProSidebar>)





  :  
  role === "manager" ?
 ( <ProSidebar collapsed={isCollapsed}>
  <Menu iconShape="square">
   
    <div className="logo-item">
      <div

      
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
         
          color: colors.grey[100],
        }}
      >
        {!isCollapsed ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            <img src="/img/newlogo.svg" alt="" />
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        ) : (


          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
           
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
    <br />
    <Box paddingLeft={isCollapsed ? undefined : "2%"} >
    <Item
        title="Dashboard"
        to="/manager-dashboard"
        icon={<DashboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />


   




      


      <Item
        title="Leads"
        to="/leads"
        icon={<LeaderboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
            title="Confirm Lead"
            to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Reminders"
            to="/reminders"
            icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
            icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
            icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />

     

    

      <Item
        title="Users"
        to="/agents"
        icon={<GroupOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />




<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
  </Menu>
</ProSidebar> ) :



( <ProSidebar collapsed={isCollapsed}>
  <Menu iconShape="square">
  
    <div className="logo-item">
      <div

     
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
    
          color: colors.grey[100],
        }}
      >
        {!isCollapsed ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            <img src="/img/newlogo.svg" alt="" />
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        ) : (


          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            {/* <img src="/img/logo.png" alt="" /> */}
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
    <br />
    <Box paddingLeft={isCollapsed ? undefined : "2%"} >
 

      <Item
        title="Leads"
        to="/leads"
        icon={<LeaderboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
              title="Confirm Lead"
              to={`/leads/?status=confirm`}
              icon={<CheckCircleSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Reminders"
            to="/reminders"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
              icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

     


      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
  </Menu>
</ProSidebar> )



}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Sidebar);

