import axios from "axios"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_KEY = process.env.REACT_APP_API_KEY;



const postsApi = axios.create({
  baseURL: BACKEND_URL,
});

postsApi.interceptors.request.use((config) => {
  config.headers['x-api-key'] = API_KEY;
  return config;
}, (error) => {
  return Promise.reject(error);
});


const Api = {

  signup: (data) => postsApi.post("/userRoute/signup", data),

  login: (postData) => postsApi.post('/userRoute/login', postData),

  changePassword:(id , data,token ) => postsApi.put(`/userRoute/change-password/${id}`,data,token ),
 
  adminChangePassword:(data ,token) => postsApi.put('/userRoute/admin/change-password' , data ,token),

  create_Reminder: (data, token) => postsApi.post("/reminderRoute/create-reminder", data, token),

  create_Meeting: (data, token) => postsApi.post("/meetingRoute/create-meeting", data, token),

  create_Task: (data, token) => postsApi.post("/taskRoute/create/task", data, token),

  // create_Contact: (data) => postsApi.post("/create/contact", data),

  // create_Compony: (data) => postsApi.post("/create/company", data),

  createLead: (data, token) => postsApi.post("/leadRoute/create-lead", data, token),

  // create_Deal: (data) => postsApi.post("/create/deal", data),

  // create_project: (data) => postsApi.post('/create-project', data),

  create_Manager: (data, token) => postsApi.post('/adminRoute/create/manager', data, token),

  create_Manager_Agent: (data, token) => postsApi.post('/adminRoute/create/manager-agent', data, token),


  create_Agent: (data, token) => postsApi.post('/managerRoute/create/agent', data, token),

  createTextNote: (data, token) => postsApi.post('/create/text', data, token),

  //===================generate Key=========================//

  generateKey:( token)=> postsApi.post("/adminRoute/admin/generate-key" , {} , token),

  showGenerateKey:(token) => postsApi.get("/adminRoute/get-secretKey" , token),

  uploadCsvToJson:(data ,token)=>postsApi.post("/adminRoute/upload-csv" ,data, token),




  
  delete_Meeting: (id, config,token) => postsApi.get(`/meetingRoute/delete-meeting/${id}`, config,token),

  delete_task: (id, config,) => postsApi.get(`/taskRoute/delete/task/${id}`, config,),

  Delete_lead: (id, config) => postsApi.get(`/leadRoute/delete/lead/${id}`, config),

  // deleteContact: (id, config) => postsApi.get(`/delete/contact/${id}`, config),

  // deleteCompany: (id, config) => postsApi.get(`/delete/company/${id}`, config),

  // deleteProject: (id, config) => postsApi.get(`/delete/project/${id}`, config),

  deleteManager: (id, config) => postsApi.get(`/adminRoute/delete/manager/${id}`, config),

  deleteAgent: (id, config) => postsApi.get(`/managerRoute/delete/agent/${id}`, config),

  deleteNote: (id, config, token) => postsApi.get(`/delete/text/${id}`, config, token),

  delete_reminder: (id, config, token) => postsApi.get(`/reminderRoute/delete-reminder/${id}`, config, token),

  assignMultipleLeads:(data , token)=> postsApi.post("/leadRoute/assign-multiple-leads" , data , token),

  get_All_Task: (token) => postsApi.get("/taskRoute/get-all/task", token),

  // get_All_Contact: () => postsApi.get("/get-all/contact"),

  // getAll_company: () => postsApi.get("/get-all/comp"),

  get_All_Leads: (token ,status ) => postsApi.get(`/leadRoute/get-all/lead?status=${status}`,token),

  get_Manager_Leads: (token ,status ) => postsApi.get(`/leadRoute/get-manager/lead?status=${status}`, token),

  get_Agent_Leads: (token , status ) => postsApi.get(`/leadRoute/get-agent/lead?status=${status}`, token),

  get_Manager_Task: (token) => postsApi.get("/taskRoute/get-manager/task", token),

  get_Agent_Task: (token) => postsApi.get("/taskRoute/get-agent/task", token),

  get_Manager_Agent: (token) => postsApi.get("/adminRoute/get-all/agent", token),

  total_Leads: (token) => postsApi.get("/adminRoute/total-leads", token),

  assign_Leads: (token) => postsApi.get("/adminRoute/assign-leads", token),

  get_Lead_Status_source_Admin: (token) => postsApi.get("/adminRoute/get-source-status/leads", token),

  get_Lead_Status_source_manager: (token) => postsApi.get("/managerRoute/get-status-source/manager", token),

  getAllManager: (token) => postsApi.get("/adminRoute/get-all/manager", token),

  getAllManager_Agent: (token) => postsApi.get("/adminRoute/get-all/manager-agent", token),

  total_Manager_Leads: (token) => postsApi.get("/managerRoute/all-manager/lead", token),

  getAllAgent: (token) => postsApi.get("/managerRoute/get/agent", token),

  get_All_Agent: (token) => postsApi.get("/managerRoute/get/all/manager-agent", token),

  getAdminAgent: (token) => postsApi.get("/adminRoute/get-all-admin-agents", token),

  getFilterLeads: (token, leadStatus) =>  postsApi.get('/leadRoute/get-filter-lead',token , {params: { leadStatus } }),

  getAllAgentUser: (token) => postsApi.get("/managerRoute/get/all/agent/", token),

  getAllNotes: (token) => postsApi.get("/get/text", token),

  getNotesByUser: (token) => postsApi.get("/get/notes", token),

  getReminder: (token) => postsApi.get("/reminderRoute/get/reminder", token),

  get_Meeting: (token) => postsApi.get("/meetingRoute/get/meetings", token),

  today_Leads: (token) => postsApi.get("/adminRoute/today-leads", token),

  today_Manager_Leads :(token) => postsApi.get("/managerRoute/today-manager-leads" ,token),

  // assign lead by admin to all users managers/admins/agents============


  getAllUsers: (token) => postsApi.get("/adminRoute/all/manager/user", token),

  getAllManagerAgent: (token) => postsApi.get("/managerRoute/get/manager/agent", token),

  get_All_Manager_AssingTo_Leads:(token) => postsApi.get("/managerRoute/get-manager/assign-leads",token   )      ,

  //===================for perticular ============================//





  get_LeadsBy_Id: (id) => postsApi.get(`/leadRoute/get-lead-by-id/${id}`),

  // getContactById: (id) => postsApi.get(`/get-contact/${id}`),

  // getCompById: (id) => postsApi.get(`/get-comp/${id}`),

  // getProjectById: (id) => postsApi.get(`/get-project/${id}`),

  getManagerById: (id) => postsApi.get(`/adminRoute/get-manager/${id}`),

  getAgentById: (id) => postsApi.get(`/managerRoute/get-agent/${id}`),


  get_Task_By_Id: (id, token) => postsApi.get(`/taskRoute/get/task/${id}`, token),

  get_Remind_By_Id: (id, token) => postsApi.get(`/reminderRoute/get/reminder/${id}`, token),

  getAdminById: ( token ) => postsApi.get(`/adminRoute/get-admin/`, token),
 




  update_Reminder: (id, data, token) => postsApi.put(`/reminderRoute/update-reminder/${id}`, data, token),

  update_Lead: (id, data, token) => postsApi.put(`/leadRoute/update-lead/${id}`, data, token),

  update_Status: (id, data, token) => postsApi.put(`/leadRoute/update-status/${id}`, data, token),

  update_Task: (id, data, token) => postsApi.put(`/taskRoute/update-task/${id}`, data, token),

  updateTask_Status: (id, data, token) => postsApi.put(`/taskRoute/update/task/status/${id}`, data, token),

  update_Meeting: (id, data, token) => postsApi.put(`/meetingRoute/update-meeting/${id}`, data, token),


  // updateContact: (id, data, token,) => postsApi.put(`/update-contact/${id}`, data, token),

  // updateCompany: (id, data, token,) => postsApi.put(`/update-company/${id}`, data, token),

  // updateProject: (id, data, token,) => postsApi.put(`/update-project/${id}`, data, token),

  editManager: (id, data, token,) => postsApi.put(`/adminRoute/update-manager/${id}`, data, token),


  editAgent: (id, data, token,) => postsApi.put(`/managerRoute/update-agent/${id}`, data, token),

  updateAdmin:(data , token) => postsApi.put("/adminRoute/update-admin/" , data , token),


//followups  

create_Follow:(data, token) => postsApi.post('/followRoute/create-follow' , data , token),

get_followups:(token) => postsApi.get("/followRoute/get-followup", token),

get_Follow_By_Id:(id , token) => postsApi.get(`/followRoute/get-followup/${id}` , token),

update_Follow:(id , data , token) => postsApi.put(`/followRoute/update-followup/${id}` , data , token),

delete_follow:(id , config , token) => postsApi.get(`/followRoute/delete-followup/${id}` , config , token),




check_Notification:(token) => postsApi.get("/notificationRoute/check-read" ,   token),

get_Notification:(token)=> postsApi.get("/notificationRoute/get-notification" , token),

delete_Notification:(id ,config , token) => postsApi.get(`/notificationRoute/delete-notification/${id}` , config, token),

count_Notification:(token)=>postsApi.get("/notificationRoute/count-notification" ,token),

update_Notification:( data , token)=>postsApi.put(`/notificationRoute/update-notification` ,data , token)

};

export default Api