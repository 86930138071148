import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { Link, NavLink } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';
import Swal from 'sweetalert2'
import InvoicesDetails from "../invoicesDetails";
import CancelIcon from '@mui/icons-material/Cancel';


import {
  Box, 
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const columns = [

  {
    field: 'items',
    headerName: 'Items',
    width: 150,
    editable: true,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    editable: true,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 110,
    editable: true,
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 110,
    editable: true,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 110,
    editable: true,
  },
  {
    field: 'discount',
    headerName: 'Discount',
    width: 110,
    editable: true,
  },

  {
    field: 'action',
    headerName: 'Action',
    width: 110,
    editable: true,
  },
];


const addBankData = async () => {

  const { value: formValues, dismiss: cancel } = await Swal.fire({
    title: "Bank Details",
    showCloseButton: true,
    html: `
      <div>
        <div style="display: flex; flex-direction: column; margin-bottom: 10px;">
          <label for="name"  style="margin-right:58%; font-size:15px">Account Holder Name:</label>
          <input id="swal-input1" class="swal2-input" style="font-size: 12px; height: 30px;" placeholder="Enter Name">
          <label for="bankName"  style="margin-right:73%; font-size:15px" >Bank Name:</label>
          <input id="swal-input2" class="swal2-input" style="font-size: 12px; height: 30px;" placeholder="Enter Bank Name">
        </div>
        <div style="display: flex; flex-direction: column;">
          <label for="accountNo"  style="margin-right:65%; font-size:15px">Account Number:</label>
          <input id="swal-input3" class="swal2-input" style="font-size: 12px; height: 30px;" placeholder="Enter Account Number">
          <label for="ifsc" style="margin-right:75%; font-size:15px">IFSC Code:</label>
          <input id="swal-input4" class="swal2-input" style="font-size: 12px; height: 30px;" placeholder="Enter IFSC Code">
        </div>
      </div>`,
    showCancelButton: true,
    confirmButtonText: "Submit",
    cancelButtonText: "Cancel",
  
    preConfirm: () => {
   
        const name = document.getElementById("swal-input1").value;
        const bankName = document.getElementById("swal-input2").value;
        const accountNo = document.getElementById("swal-input3").value;
        const ifsc = document.getElementById("swal-input4").value;
      

        if(!name){Swal.showValidationMessage("Please fill your name")}
       else if(!bankName){Swal.showValidationMessage("Please fill your bank name")}
       else if(!accountNo){Swal.showValidationMessage("Please fill your account number")}
       else if(!ifsc){Swal.showValidationMessage("Please fill your IFSC code")}
       else{
        return [name,bankName,accountNo,ifsc]
       }
    }
  });

  if (formValues) {
    Swal.fire(JSON.stringify(formValues));
  } else if (cancel) {
    Swal.fire('Cancelled');
  }
}




const rows = [
  { id: 1, Items: <input />, Category: <input />, Quantity: <input />, Price: <input />, Amount: <input />, Discount: <input />, Action: "dfgfd" },


];



const useStyles = makeStyles((theme) => ({
  input: {
    border: '1px solid rgb(223, 219, 219)',
    borderRadius: "5px",
    outline: 'none',

    padding: "5px 10px 5px 10px",

    width: '100%', // Ensure input takes full width

  },
}));


const AddInvoice = ({ isLogging }) => {



  const handleUploadButtonClick = () => {
    // Trigger click event of the file input element
    document.getElementById('fileInput').click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    // Handle file upload logic here, e.g., send the file to the server
    console.log('Uploaded file:', file);
  };

  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
  const [project, setProject] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [discount, setsetDiscount] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);


  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  
  const handleDateClick = (date) => {
    setSelectedDate(date);
    toggleCalendar();
  };

  const [rows, setRows] = useState([{
    Items: '', Category: '', Quantity: '', Price: '', Amount: '', Discount: '', Action: ''
  }]);

 

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const deleteRows = () => {
    if (rows.length > 0) {
      setRows(rows.slice(0, -1)); // remove the last row
    }
  };


  const addRows = () => {
    setRows([...rows, { Items:    <InputBase
      className={classes.input}
      type="text"

    />
, Category:    <InputBase
className={classes.input}
type="text"

/>
, Quantity:    <InputBase
className={classes.input}
type="text"

/>
, Price:   <InputBase
className={classes.input}
type="text"

/>
, Amount:   <InputBase
className={classes.input}
type="text"

/>
, Discount:    <InputBase
className={classes.input}
type="text"

/>
, Action:  <div style={{ display: "flex" }}> <NavLink ><AddCircleSharpIcon sx={{ color: "#9B56FF"  }} onClick={addRows} /></NavLink>
<NavLink><ContentCopyRoundedIcon sx={{ color: "gray" }} /></NavLink>
<NavLink><DeleteOutlineOutlinedIcon sx={{ color: "#FE0000" }} onClick={deleteRows} /> </NavLink>  </div>}]);
  };



  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {

    try {
      const response = await Api.get_All_Project()

      if (response.data && Array.isArray(response.data.data)) {
        setProject(mapPlotData(response.data.data))
        console.log(response.data.data)
      } else {
        console.log("Data is not an array or is missing:'", response.data.dataresponse.data.data)
      }
    } catch (error) {
      console.log('Error fetching data:', error)
    }
  }






  useEffect(() => {
    !isLogging && navigate('/')
  }, [])

  // const columns = [
  //   { field: "sno", headerName: "S.No", width: 30 },

  //   { field: "project_name", headerName: "Project Name", width: 170 },
  //   { field: "project_status", headerName: "Project Status", width: 170 },

  //   { field: 'project_category', headerName: 'project_category', width: 170 },
  //   { field: 'pipeline', headerName: 'Pipeline ', width: 170 },
  //   { field: 'project_created', headerName: 'Project Created ', width: 170 },


  //   {
  //     field: 'blank',
  //     headerName: ''
  //     , width: 20,
  //     renderCell: (params) => (
  //       <img src="/img/star.png" alt="" />
  //     ),
  //   }

  //   ,

  //   , {
  //     field: 'action',
  //     headerName: 'Action'
  //     , width: 40,
  //     renderCell: (params) => (
  //       <img src="/img/dots3.png" alt="" />
  //     ),
  //   },




  //   // { field: "location", headerName: "Location", flex: 1 },
  //   // { field: "type", headerName: "Type", flex: 1 },
  //   // {
  //   //   field: 'edit',
  //   //   headerName: 'Edit',
  //   //   flex: 1,
  //   //   width: 100,
  //   //   renderCell: (params) => (
  //   //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id,params.row.type)}>
  //   //       Edit
  //   //     </Button>
  //   //   ),
  //   // },
  //   // {
  //   //   field: 'delete',
  //   //   headerName: 'Delete',
  //   //   flex: 1,
  //   //   width: 100,
  //   //   renderCell: (params) => (
  //   //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id,params.row.type)}>
  //   //       Delete
  //   //     </Button>
  //   //   ),
  //   // },
  // ];

  useEffect(() => {



    const staticData = [
      { id: 1, sno: 1, project_name: 'gh', project_status: 'abc ', project_category: 'user1', project_created: "12/69/4567" },

      // Add more tasks as needed
    ];
    setPlotData(staticData);

    // fetch(`${BACKEND_URL}/admin/featured-property`)
    //   .then((response) => response.json())        
    //   .then((data) => {
    //     // console.log(data);
    //     setPlotData(mapPlotData(data.data));
    //   })
    //   .catch((error) => console.error("Error fetching plot data:", error));
  }, []
    //  [isSnackbarOpen]
  ); // Add isSnackbarOpen as a dependency

  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      project_name: d.projectName || "No",
      project_status: d.status || "No",
      project_category: d.category || "No",
      pipeline: d.progress,
      project_created: d.createdAt.slice(0, 10),

    }));
  };



  const Itemsss = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));







  const handleAddCharge = () => {
    setAdditionalCharges([...additionalCharges, { id: Date.now() }]);
  }

  const handleRemoveCharge = (id) => {
    setAdditionalCharges(additionalCharges.filter(charge => charge.id !== id));
  };

  const handleAddDiscount = () => {
    setsetDiscount([...discount, { id: Date.now() }]);
  }

  const handleRemoveDiscount = (id) => {
    setsetDiscount(discount.filter(charge => charge.id !== id));
  };


  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    { value: 'option1', label: 'kareena' },
    { value: 'option2', label: 'prem' },
    { value: 'option3', label: 'deepika' },
  ];
  const handleDeleteData = () => {
    Swal.fire({
      title: "Delete Invoice Details",
      text: "Are you sure want to delete?",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9B56FF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your invoice has been deleted.",
          // icon: "success"
        });
      }
    });
  }


  const handleSaveData = () => {
    Swal.fire({
      title: "Save Invoice Details",
      text: "Are you sure want to save?",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9B56FF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Saved!",
          text: "Your invoice has been saved.",
          // icon: "success"
        });
      }
    });
  }





  return (
    <Box m="20px" className="margin_in_mobile_ipad">

      <Header title="Back to Invoice List"
        subTitle="" handleDeleteData={handleDeleteData} handleSaveData={handleSaveData} showPopup={showPopup} setShowPopup={setShowPopup}
      />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius: "10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        {/* <DataGrid
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          rows={project}
          columns={columns}
        /> */}



        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: "20px" }} className="container-details" >

          <div>

            <h3>Customer Name</h3>
            <FormControl fullWidth>

              <Select
                value={selectedOption}
                onChange={handleChange}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <h3>Po Number</h3>



            <InputBase
              className={classes.input}
              type="text"
              placeholder="Enter Reference Number"
            />


          </div>


          <div>
      <h3>Invoice details</h3>
      <div style={{ border: "1px solid rgb(223, 219, 219)", borderRadius: "10px" }}>
      <p style={{ padding: "10px", fontSize: "15px" }}>Invoice No.  <NavLink to="/invoices-details" style={{color:"#9B56FF", textDecoration:"none"}}>IN093439#@09</NavLink> </p>
        <Divider />
        <p style={{ padding: "10px", fontSize: "15px" }}>
        Date
        <span style={{color: '#9B56FF' }} onClick={() => handleDateClick( Date.now())}> {new Date().toLocaleDateString()}</span> 
        </p>
        <p style={{ padding: "10px", fontSize: "15px" }}>
        Due Date <span style={{color: '#9B56FF'  }} onClick={() => handleDateClick(Date.now())}> {new Date().toLocaleDateString()} </span>
        </p>
      </div>
      {showCalendar && (
        <div className="calendar">
          {/* Display calendar based on the selected date */}
       { selectedDate }
        </div>
      )}
    </div>
    <div>

<h3>GST Number</h3>
<FormControl fullWidth>

  <Select
    value={selectedOption}
    onChange={handleChange}
  >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<h3>Pan Number</h3>



<InputBase
  className={classes.input}
  type="text"
  placeholder="Enter Reference Number"
/>


</div>
        </div>




        <br />

        <hr />


        <div className="container-address" style={{ display: "flex", width: "50%", justifyContent: "space-between" }} >
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div>
                <h3>  Invoice From</h3>
              </div>
              <div>
                <Link to="/edit-invoice" style={{ color: "#9B56FF", textDecoration: "none" }}> <h3 style={{}}>Edit Address</h3></Link>
              </div>


            </div>

            <div style={{marginTop:"-13px"}}>
            <div >
             


            </div>
            <div>
              <p>
              Abhijeet
                <br />
                Vip Road ,Zirakpur
                <br />
                Florida, 32405, USA
              </p>


            </div>

          </div>

          </div>

          <div style={{}}>
            <div >
              <div>
                <h3>  Invoice To</h3>
              </div>


            </div>
            <div>
              <p>
               Param Singh
                <br />
                Sector 8 , 160520 Chandigarh
                <br />
                Punjab , India
              </p>


            </div>

          </div>

        </div>


        <div>
          <h3 style={{ color: "#9B56FF" }}>
            Item Details
          </h3>
        </div>




        <TableContainer >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow >
                <TableCell>  <h3 >Items</h3>


                  <InputBase
                    className={classes.input}
                    type="text"

                  />

                </TableCell>
                <TableCell  >  <h3  >Category</h3>

                  <InputBase
                    className={classes.input}
                    type="text"

                  />
                </TableCell>
                <TableCell >  <h3  >Quantity</h3>

                  <InputBase
                    className={classes.input}
                    type="text"

                  />
                </TableCell>
                <TableCell >  <h3  >Price</h3>

                  <InputBase
                    className={classes.input}
                    type="text"

                  />
                </TableCell>
                <TableCell >  <h3  >Amount </h3>

                  <InputBase
                    className={classes.input}
                    type="text"

                  />
                </TableCell>
                <TableCell >  <h3  >Discount</h3>

                  <InputBase
                    className={classes.input}
                    type="text"

                  />
                </TableCell>
                <TableCell  >
                  <h3 >Action</h3>
                  


                  <div style={{ display: "flex" }}> <NavLink ><AddCircleSharpIcon sx={{ color: "#9B56FF"  }} onClick={addRows} /></NavLink>
                    <NavLink><ContentCopyRoundedIcon sx={{ color: "gray" }} /></NavLink>
                    <NavLink><DeleteOutlineOutlinedIcon sx={{ color: "#FE0000" }} onClick={deleteRows} /> </NavLink>  </div>


                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  // key={row.items}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Items}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Category}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Quantity}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Price}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Amount}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px" }}>     {row.Discount}</p></TableCell>
                  <TableCell >  <p style={{ fontSize: "15px", textAlign: "right" }}>     {row.Action}</p></TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>







        {/* <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
       rows={rows}
        columns={columns}
        // initialState={{
          // pagination: {
          //   paginationModel: {
          //     pageSize: 5,
          //   },
          // },
        // }}
        // pageSizeOptions={[5]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>   */}

        <div>

        </div>

        <div className="payment-details" style={{ display: "grid", gridTemplateColumns: "60% 2% 1fr" }}>


          <div >
            <h3>
              More Fields
            </h3>
            <div >
              <Box >
                <div style={{ border: "1px solid rgb(223, 219, 219)", borderRadius: "10px", padding: "20px " }}> <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "700" }}>
                    Payment Details
                  </Typography>
                </Stack>

                  <Typography color="text.secondary" variant="body2" marginTop="7px">
                    <Button style={{ backgroundColor: "#9B56FF", color: "white", width: "fit-content ", display: "flex", gap: "10px" }} onClick={addBankData}><AddCircleSharpIcon sx={{ color: "white", fontSize: "25px" }} />Add Bank details</Button>
                  </Typography></div>
                <br />
                <div style={{ marginTop: "10px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography> <AddCircleSharpIcon sx={{ color: "gray", marginBottom: "-3px", fontSize: "15px" }} />Add Terms & Conditions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <textarea
              className={classes.input}
              type="text"
           style={{outline:"none" , border:"white"}}
            />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{ marginTop: "10px" }}>
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography> <AddCircleSharpIcon sx={{ color: "gray", marginBottom: "-3px", fontSize: "15px" }} /> Add Notes</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                    <textarea
              className={classes.input}
              type="text"
           style={{outline:"none" , border:"white"}}
            />
                    </AccordionDetails>
                  </Accordion>
                </div>

              </Box>




            </div>
          </div>


          <div>
            {" "}
          </div>


          <div>
            <h3>Summary</h3>
            <div style={{ border: "1px solid rgb(223, 219, 219)", borderRadius: "10px" }}>
              <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "700" }}>
                    Taxable Amount
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    $4.50
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: "10px" }}>
                  <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "600" }}>
                    Round Off <Switch defaultChecked size="small" color="#9B56FF" />
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    $14.50
                  </Typography>
                </Stack>


                <br />

                <NavLink onClick={handleAddCharge} style={{ fontWeight: "600", textDecoration: "none" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <AddCircleSharpIcon sx={{ color: "#9B56FF", fontSize: "25px" }} /> Additional Charges
                  </div>
                </NavLink>

                {additionalCharges.map(charge => (
                  <div key={charge.id}>
                    <p></p>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: "10px" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "600" }}>
                        <NavLink style={{ fontWeight: "600", textDecoration: "none", fontSize: "13px", color: "black" }}>
                          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <RemoveCircleSharpIcon onClick={() => handleRemoveCharge(charge.id)} sx={{ color: "black", fontSize: "25px", cursor: "pointer" }} />   Service Charge
                          </div>
                        </NavLink>

                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        $4
                      </Typography>
                    </Stack>

                  </div>
                ))}



                <p></p>
                <br />
                <NavLink onClick={handleAddDiscount} style={{ fontWeight: "600", textDecoration: "none" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <AddCircleSharpIcon sx={{ color: "#9B56FF", fontSize: "25px" }} /> Additional Charges
                    </div>
                  </div>
                </NavLink>

                {discount.map(charge => (
                  <div key={charge.id}>
                    <p></p>


                    <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: "10px" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "600" }}>
                        <NavLink style={{ fontWeight: "600", textDecoration: "none", fontSize: "13px", color: "black" }}>
                          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <RemoveCircleSharpIcon onClick={() => handleRemoveDiscount(charge.id)} sx={{ color: "black", fontSize: "25px", cursor: "pointer" }} />   Offer New
                          </div>
                        </NavLink>

                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        $4 %
                      </Typography>
                    </Stack>




                  </div>
                ))}

              </Box>

              <Divider />
              <Box style={{ padding: "10px 20px" }} >
                <Typography variant="body2">
                  <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: "10px" }}>
                    <Typography variant="h5" component="div" style={{ color: "#9B56FF" }}>
                      Total Amount
                    </Typography>
                    <Typography variant="h5" component="div" style={{ color: "#9B56FF" }}>
                      $ 894.00
                    </Typography>
                  </Stack>
                </Typography>
              </Box>



            </div>
            <br />

            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{}}>
                {/* Hidden file input */}
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                />
                {/* Button to trigger file input */}
                <button
                  onClick={handleUploadButtonClick}
                  style={{ padding: "10px 45px ", border: '1px solid #9B56FF', background: 'none', cursor: 'pointer', borderRadius: "5px", color: "#9B56FF" }}
                >
                  Upload Image
                </button>
              </div>
            </div>
            <br />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <div>

                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Name"
                />
              </div>
            </div>
            <br />

            <div style={{ textAlign: "right" }}>
              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }} >Save Invoice</Button>
            </div>

          </div>
          {/* <div >
<h3>
Summary
</h3>

<Card variant="outlined"    >
    <Box sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography gutterBottom variant="h5" component="div"  style={{fontWeight:"700"}}>
        Taxable Amount
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          $4.50
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{marginTop:"10px"}}>
        <Typography gutterBottom variant="h5" component="div"  style={{fontWeight:"600"}}>
        Round Off  <Switch {...label} defaultChecked size="small" color="#9B56FF" />
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          $14.50
        </Typography>
      </Stack>

      <NavLink style={{fontWeight:"600" ,textDecoration:"none"}}><AddCircleSharpIcon sx={{color:"#9B56FF", marginBottom:"-1px" ,fontSize:"15px"}} /> Additional Charges
      </NavLink>  
      <p></p>
      <NavLink style={{fontWeight:"600" ,textDecoration:"none"}}><AddCircleSharpIcon sx={{color:"#9B56FF", marginBottom:"-1px" ,fontSize:"15px"}} /> Add More Discount
      </NavLink>  
  
    </Box>
   
    <Divider />
    <Box sx={{ p: 2}}>
      <Typography gutterBottom variant="body2">
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{marginTop:"10px"}}>
        <Typography gutterBottom variant="h5" component="div"  style={{fontWeight:"600" ,color:"#9B56FF"}}>
       <h3>Total Amount</h3>
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
         <h2 style={{color:"#9B56FF"}}>$ 894.00 </h2>
        </Typography>
      </Stack>
      </Typography>
    
  
    </Box>
  </Card>
</div> */}

        </div>

        <br />



        {/* 
  <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={7}>
          <Item>
            <div style={{display:"flex"}}>
            <h3 style={{fontWeight:"700" ,}}>Payment Details</h3>
            </div>
          
<Button style={{backgroundColor:"#9B56FF"  ,color:"white",height:"70px" ,width:"50% " ,display:"flex"}} ><AddCircleSharpIcon sx={{color:"white" ,size:"10px"}}/>Add Bank details</Button>






          </Item>
        </Grid>
        <Grid item xs={6} md={5}>
        <Item>
            <div style={{display:"flex"}}>
            <h3 style={{fontWeight:"700" ,}}>Payment Details</h3>
            </div>
          
<Button style={{backgroundColor:"#9B56FF"  ,color:"white",height:"70px" ,width:"50% " ,display:"flex"}} ><AddCircleSharpIcon sx={{color:"white" ,size:"10px"}}/>Add Bank details</Button>

<div>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography> <AddCircleSharpIcon sx={{color:"gray", marginBottom:"-3px" ,fontSize:"15px"}}/>Add Terms & Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography> <AddCircleSharpIcon sx={{color:"gray", marginBottom:"-3px" ,fontSize:"15px"}}/> Add Notes</Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>




          </Item>
        </Grid>
      </Grid>
    </Box> */}

      </Box>


      {/* Delete Confirmation Dialog */}
      {/* <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Success Snackbar */}
      {/* <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" /> */}


      {showPopup && (
        <div className="overlay ">
          <div className="popup11 animate__animated animate__fadeInDown   ">
            <div style={{ position: "absolute", top: "20px", right: "40px" }}
              onClick={() => { setShowPopup(!showPopup) }}
            >
              <CancelIcon sx={{ fontSize: "40px" }} />
            </div>
            <div style={{ overflowY: "auto", height: "100vh" }}>   <InvoicesDetails /></div>

          </div>
        </div>
      )}











    </Box>

  );
};

export default AddInvoice;

