import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, TextField, Select, MenuItem, FormControl, Typography, Button, } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { connect } from "react-redux";
import ReactQuill from "react-quill";

const EditLead = ({ role }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const token = getCookie("leadmanagement");


  const [leadData, setLeadData] = useState({
    firstName: "", lastName: "", email: "", phoneNumber: "", alternateNumber: "",
    city: "", state: "", street: "", country: "", zipCode: "",
    industry: "", owner: "", website: "", employNumber: "", leadSource: "",
    leadStatus: "",projectCode:"",
    title: "", description: "", assignTo: "", additionalInformation: [], notes: [] , faq:[] , pricing:[]
  })
  const [faq, setFaq] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [pricing,setPricing ] = useState([]);
  const [amount, setAmount] = useState("");
  const [payment, setPayment] = useState("");

  useEffect(() => {
    if (id) fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.get_LeadsBy_Id(id);
      console.log(response.data.data);
      setLeadData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const backToPage = () => {
    navigate("/leads");
  };

  const handleUpdate = async () => {
    try {
      const response = await Api.update_Lead(id, { ...leadData, question: question, answer: answer }, token);

      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/leads"),
        });
      }
    } catch (error) {
      console.error("Error updating property:", error);
    }
  };


  const handleAddPricing = () => {
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      pricing: [
        ...prevLeadData.pricing,
        { amount: "", payment: "" }
      ]
    }));
  };


  const handlePricingCancel = () => {
    setLeadData(prevLeadData => {
      const updatedPricing = [...prevLeadData.pricing];
      updatedPricing.pop();
      return {
        ...prevLeadData,
        pricing: updatedPricing
      };
    });
  };

  const handlePricingInputChange = (index, field, value) => {
    const updatedPricing = [...leadData.pricing];
    updatedPricing[index][field] = value;
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      pricing: updatedPricing
    }));
  };



  const handleAddFaq = () => {
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      faq: [
        ...prevLeadData.faq,
        { question: "", answer: "" }
      ]
    }));
  };


  const handleFaqCancel = () => {
    setLeadData(prevLeadData => {
      const updatedAdditionalInformation = [...prevLeadData.faq];
      updatedAdditionalInformation.pop();
      return {
        ...prevLeadData,
        faq: updatedAdditionalInformation
      };
    });
  };

  const handleFaqInputChange = (index, field, value) => {
    const updatedAdditionalInformation = [...leadData.faq];
    updatedAdditionalInformation[index][field] = value;
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      faq: updatedAdditionalInformation
    }));
  };

  const handleAdd = () => {
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      additionalInformation: [
        ...prevLeadData.additionalInformation,
        { firstName: "", lastName: "", email: "", phone: "" }
      ]
    }));
  };


  const handleCancel = () => {
    setLeadData(prevLeadData => {
      const updatedAdditionalInformation = [...prevLeadData.additionalInformation];
      updatedAdditionalInformation.pop();
      return {
        ...prevLeadData,
        additionalInformation: updatedAdditionalInformation
      };
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedAdditionalInformation = [...leadData.additionalInformation];
    updatedAdditionalInformation[index][field] = value;
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      additionalInformation: updatedAdditionalInformation
    }));
  };


  const handleAddNote = () => {
    setLeadData(prevLeadData => ({
      ...prevLeadData,
      notes: [
        ...prevLeadData.notes,
        { text: "" }
      ]
    }));
  };


  const handleCancelNote = () => {
    setLeadData(prevLeadData => {
      const updatedAdditionalInformation = [...prevLeadData.notes];
      updatedAdditionalInformation.pop();
      return {
        ...prevLeadData,
        notes: updatedAdditionalInformation
      };
    });
  };

  const handleNoteInputChange = (index, value) => {
    setLeadData(prevLeadData => {
      const updatedNotes = prevLeadData.notes.map((note, i) => 
        i === index ? { ...note, text: value } : note
      );
      return {
        ...prevLeadData,
        notes: updatedNotes
      };
    });
  };




  const handlePricing = () =>{
    if(amount && pricing){
      setPricing([...pricing, { amount: amount, payment: payment }]);
      setAmount("");
      setPayment("");
    }

  }

  const handleRemovePricing = () => {
    setPricing(pricing.slice(0, -1));
  };

  const handleFaq = () => {

    if (question && answer) {
      setFaq([...faq, { question: question, answer: answer }]);
      setQuestion("");
      setAnswer("");
    }
  };
  const removeFaq = () => {
    setFaq(faq.slice(0, -1));
  };
  
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === "admin") {
          response = await Api.getAllUsers(token);
        } else if (role === "manager") {
          response = await Api.getAllManagerAgent(token);
        } else if (role === "agent") {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [role]);

  return (
    <>
      <Topbar />

      {leadData && (
        <>
          <Box m="20px" className="margin_in_mobile_ipad" sx={{}}>
            <div style={{ padding: " 20px", alignItems: "center" }}>
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Lead Information
                  </Typography>

                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto   ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="First Name"
                      value={leadData.firstName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({ ...leadData, firstName: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Last Name"
                      value={leadData.lastName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({ ...leadData, lastName: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Email"
                      value={leadData.email}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 35) {
                          setLeadData({ ...leadData, email: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Phone"
                      value={leadData.phoneNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({ ...leadData, phoneNumber: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Alternate Number"
                      value={leadData.alternateNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({
                            ...leadData,
                            alternateNumber: inputText,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Box>
                  {leadData.additionalInformation.map((info, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Typography variant="h4" fontWeight="550" sx={{ m: "0 0 5px 0", }} className="heading_animation">
                    Additional Information {index + 1}
                  </Typography>
                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{ display: "grid", gridTemplateColumns: "auto auto", gap: "20px" }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id={`first-name-${index}`}
                      label="First Name"
                      value={info.firstName}
                      onChange={(e) =>

                        handleInputChange(index, 'firstName', e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      className="class_input"
                      id={`last-name-${index}`}
                      label="Last Name"
                      value={info.lastName}
                      onChange={(e) => handleInputChange(index, 'lastName', e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      type="email"
                      className="class_input"
                      id={`email-${index}`}
                      label="Email"
                      value={info.email}
                      onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      className="class_input"
                      id={`phone-${index}`}
                      label="Phone"
                      type="number"
                      value={info.phone}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          handleInputChange(index, 'phone', inputText);
                        }
                      }}
                      variant="outlined"
                    />

                  </Box>
                </div>
              ))}


              <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>


                <div style={{ display: "flex", gap: "10px" }}>

                  <Button sx={{

                    color: "#9B56FF",
                    border: " 1px solid #9B56FF",
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF"
                    }
                  }}

                    onClick={() => { handleAdd() }}
                  >Add</Button>


                  {leadData.additionalInformation.length > 0 && (
                    <Button
                      sx={{
                        color: "#FF0000",
                        border: "1px solid #FF0000",
                        '&:hover': {
                          backgroundColor: '#ffffff',
                          color: "#FF0000",
                          border: "1px solid #FF0000"
                        }
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  )}


                </div>


              </div>


                



                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Address Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Street"
                      value={leadData.street}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 40) {
                          setLeadData({ ...leadData, street: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="City"
                      value={leadData.city}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, city: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="State"
                      value={leadData.state}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, state: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Country"
                      value={leadData.country}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, country: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Postal Code"
                      value={leadData.zipCode}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 10) {
                          setLeadData({ ...leadData, zipCode: inputText });
                        }
                      }}
                      variant="outlined"
                    />


<TextField
                  className="class_input"
                  id="outlined-controlled"
                  label="Project Code"
                  value={leadData.projectCode}
                  onChange={(e) => {
                    const inputText = e.target.value;
                      setLeadData({ ...leadData, projectCode: inputText });
                  }}
                  variant="outlined"
                />
                  </Box>
                </Box>
              </div>
              <br /> <br />
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Company Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Company Name"
                      value={leadData.industry}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, industry: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Owner"
                      value={leadData.owner}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, owner: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="WebSite"
                      value={leadData.website}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, website: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <Grid>
                      <div>Number of Employees</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={leadData.employNumber}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              employNumber: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="1-9employees">
                            1-9 employees
                          </MenuItem>
                          <MenuItem value="10-50employees">
                            10-50 employees
                          </MenuItem>
                          <MenuItem value="50-200employees">
                            {" "}
                            50-200 employees
                          </MenuItem>
                          <MenuItem value="200+">
                            more than 200 employees
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Additional Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto   ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid>
                      <div>Lead Status</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={leadData.leadStatus}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              leadStatus: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="new">New</MenuItem>
                      <MenuItem value="processing">In discussion</MenuItem>
                      <MenuItem value="confirm">Hired</MenuItem>
                      <MenuItem value="cancel">Cold/Dead</MenuItem>
                      <MenuItem value="hot">Hot</MenuItem>
                      <MenuItem value="hold">Hold</MenuItem>
                      <MenuItem value="negotiation">Negotiation</MenuItem>
                      <MenuItem value="lost/won">Lost/Won</MenuItem>
                      <MenuItem value="dispatch/delivered">Dispatch/Delivered</MenuItem>
                      <MenuItem value="technical">Technical Evaluation</MenuItem>
                      <MenuItem value="finincial">Finincial Evaluation</MenuItem>
                      <MenuItem value="visit">Visit Planning</MenuItem>
                      <MenuItem value="revised">Revised Quotation</MenuItem>
                      <MenuItem value="oppurtunity">Oppurtunity</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <div>Lead Source</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="first-select-label"
                          id="first-select"
                          fullWidth
                          defaultValue=""
                          value={leadData.leadSource}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              leadSource: e.target.value,
                            })
                          }
                        >
                    <MenuItem value="businessdevelopement">Business Developement</MenuItem>
                      <MenuItem value="indiamart">Indiamart</MenuItem>
                      <MenuItem value="email">Direct Email </MenuItem>
                      <MenuItem value="call">Direct Call </MenuItem> 
                      <MenuItem value="reference">Reference </MenuItem>
                      <MenuItem value="linkdin">Linkdin </MenuItem>
                      <MenuItem value="youtube">Youtube </MenuItem>
                      <MenuItem value="glolocal">Glolocal </MenuItem>
                      <MenuItem value="website">Website Enquiry </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <div>User Responsible</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="third-select-label"
                          id="third-select"
                          fullWidth
                          defaultValue=""
                          value={leadData.assignTo._id || ""}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              assignTo: {
                                ...leadData.assignTo,
                                _id: e.target.value,
                              },
                            })
                          }
                        >
                          {users.map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Box>
                </Box>
              </div>
              <br />
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Title
                  </Typography>

                  <br />
                  <br />

                  <TextField
                    label="Title"
                    type="text"
                    id="second-text-field"
                    value={leadData.title}
                    multiline
                    onChange={(e) => {
                      const inputText = e.target.value;
                      const words = inputText.trim().split(/\s+/);
                      if (words.length <= 100) {
                        setLeadData({ ...leadData, title: inputText });
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                  <br />
                  <br />


               {leadData.notes.map((note, index) => (
<>
<Typography variant="h4" fontWeight="550" sx={{ m: "20px 0" }} className="heading_animation">
              Add  Notes {index +1}
              </Typography>
<Box key={index} sx={{ marginBottom: "20px" }}>
                  <TextField
                    className="class_input"
                    id={`note-${index}`}
                    label={`Note ${index + 1}`}
                    value={note.text}
                    onChange={(e) => handleNoteInputChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Box>





</>
                
                
              ))}


<div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>


                <div style={{ display: "flex", gap: "10px" }}>

                <Button sx={{
              
              color: "#9B56FF",
              border: " 1px solid #9B56FF",
              '&:hover': {
                backgroundColor: '#ffffff',
                color: "#9B56FF",
                border: " 1px solid #9B56FF"
              }
            }}

              onClick={() => { handleAddNote()}}
            >Add Note</Button>

            {leadData.notes.length > 0 && (
              <Button
                sx={{
                  color: "#FF0000",
                  border: "1px solid #FF0000",
                  '&:hover': {
                    backgroundColor: '#ffffff',
                    color: "#FF0000",
                    border: "1px solid #FF0000"
                  }
                }}
                onClick={handleCancelNote}
              >
                Cancel
              </Button>
            )}


                </div>


              </div>



                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Description
                  </Typography>
                  <br />
                  <br />
                  <Box component="form" noValidate autoComplete="off">

                  <ReactQuill theme="snow" value={leadData.description}    onChange={(e) => {
                          const inputText = e;
                          const words = inputText.trim().split(/\s+/);
                          if (words.length <= 500) { 
                            setLeadData({ ...leadData, description: inputText });
                          }
                        }} />
                    
                    {/* <TextField
                      label="Description"
                      type="text"
                      id="second-text-field"
                      value={leadData.description}
                      multiline
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const words = inputText.trim().split(/\s+/);
                        if (words.length <= 500) {
                          setLeadData({ ...leadData, description: inputText });
                        }
                      }}
                      style={{ width: "100%" }}
                    /> */}
                  </Box>
                </Box>
              </div>



              {/* question and answer */}



              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >






                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >

                   {leadData.faq.map((data, index) => (
<>
<Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    FAQ
                  </Typography>

                  <br />
                  <br />

                  <TextField
                    label="Question"
                    type="text"
                    id="second-text-field"
                    value={data.question}
                    multiline
                    onChange={(e) =>
                      handleFaqInputChange(index, 'question', e.target.value)}
                    style={{ width: "100%" }}
                  />

                  <br/>
                  <br/>

                  <TextField
                      label="Answer"
                      type="text"
                      id="second-text-field"
                      multiline
                      value={data.answer}
                      onChange={(e) =>
                        handleFaqInputChange(index, 'answer', e.target.value)}
                      style={{ width: "100%" }}
                    />


</>




                   ))}



                 

<div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                      <Button
                        sx={{
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={() => {
                          handleAddFaq();
                        }}
                      >
                        Add Question
                      </Button>
                      
                      {leadData.faq.length > 0 && 
                      
                      <Button
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={handleFaqCancel}
                      >
                        {" "}
                        Cancel
                      </Button>
                      
                      }

                      
                    </div>
                  </div>

                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >

{leadData. pricing.map((data, index) => (

<>
<Typography
variant="h4"
fontWeight="550"
sx={{ m: "0 0 5px 0" }}
className="heading_animation"
>
Pricing
</Typography>

<br />
<br />

<TextField
                   type="number"
                    label="Amount"
                    id="second-text-field"
                    value={data.amount}
                    multiline
                    onChange={(e) =>
                      handlePricingInputChange(index, 'amount', e.target.value)}
                    style={{ width: "100%" }}
                  />

                  <br/>
                  <br/>

                  <TextField
                      type="number"
                      label="Payment"
                      id="second-text-field"
                      multiline
                      value={data.payment}
                      onChange={(e) =>
                        handlePricingInputChange(index, 'payment', e.target.value)}
                      style={{ width: "100%" }}
                    />

</>

)
                   

                  
                      
                    
                    
                  )}




               

                


               


<div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                      <Button
                        sx={{
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={() => {
                          handleAddPricing();
                        }}
                      >
                        Add Payment
                      </Button>

                      {
                        leadData.pricing.length > 0 && 
                        <Button
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={handlePricingCancel}
                      >
                        {" "}
                        Cancel
                      </Button>
                      }

                
                    </div>
                  </div>
                 

                 
                 


                
                </Box>

              </div>

              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    sx={{
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update
                  </Button>

                  <Button
                    sx={{
                      backgroundColor: "#9B56FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={backToPage}
                  >
                    {" "}
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(EditLead);
