import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PlotListing = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const columns = [
    { field: 'sno', headerName: 'S.No', width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'userName', headerName: 'UserName', flex: 1 },
    { field: 'location', headerName: 'Location', flex: 1 },
    { field: 'featured', headerName: 'Featured', flex: 1 },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id)}>
          Edit
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id)}>
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetch(`${BACKEND_URL}/admin/get-plot`)
      .then((response) => response.json())
      .then((data) => {
        setPlotData(mapPlotData(data.data))
      })
      .catch((error) => console.error('Error fetching plot data:', error));
  }, [isSnackbarOpen]); // Add isSnackbarOpen as a dependency

  const mapPlotData = (data) => {
    return data.map((plot,index) => ({
      sno:index+1,
      id: plot._id,
      title: plot.name || 'No',
      userName: plot.host[0].firstName || 'No',
      location: plot.address.city || 'No',
      featured: plot.Featured ? "Yes" : "No",
    }));
  };

  const handleEdit = (id) => {
    console.log(`Edit plot with ID ${id}`);
  };

  const handleDeleteClick = (id) => {
    setSelectedPlotId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Call your API to delete the plot with selectedPlotId 
      // Example:
       await axios.delete(`${BACKEND_URL}/admin/delete-listing-plot/${selectedPlotId}`);
      setIsSnackbarOpen(true);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting plot:', error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Box m="20px">
      <Header title="Plot Listing" subtitle="Displaying Plot Listing" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: 'none',
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={plotData} columns={columns} />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" />
    </Box>
  );
};

export default PlotListing;
