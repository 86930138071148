import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Snackbar,
} from "@mui/material";
import Header from "../../components/Header";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Topbar from "../global/Topbar";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { connect } from "react-redux";
import { getCookie } from "../../components/Cookies";



const options = [
  "Save Changes",
  "Localization",
  "Payment Setting",
  "Email Setting",
  "Social Media Login",
  " Social Links",
  " SEO Setting",
  " Others",
];







const SmptMail = ({ isLogging, role }) => {
  const navigate = useNavigate();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = getCookie("leadmanagement");
  const [allData, setAllData] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {

    try {
      setLoading(true)
      const response = await Api.getAdminById(token);
      setLoading(false)
      setAllData(response.data.data)

    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  }




  const handleUpdateAdmin = async () => {

    try {
      setLoading(true)
      const response = await Api.updateAdmin(allData, token)
      if (response.status === 200) {
        setLoading(false)
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/setting"),
        });
        fetchData()
      }
    } catch (error) {
      console.log(error)
    }



  }


  useEffect(() => {
    !isLogging && navigate("/");
  }, []);



  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };



  const handleChange = (e) => {
    setAllData(() => {
      return { ...allData, [e.target.name]: e.target.value }
    })
    console.log(allData)
  }
  return (
    <>
      <Topbar />
      <Box m="30px " className="margin_in_mobile_ipad">
        <Header title="Settings" subTitle="SMTP Mail Setting" />

        <Box>
          <div
            className="respons1"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              width: "100%",
              gap: "10px",
            }}
          >
            {options.map((data, index) => {
              return (
                <Button
                  sx={{
                    color: "#9B56FF",
                    border: " 1px solid #9B56FF",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF",
                    },
                  }}
                >
                  {data}
                </Button>
              );
            })}
          </div>
        </Box>
        <br />
        <Box
          className="display11"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              padding: " 0px 20px",
              borderRadius: "10px",
              width: "100%",
            }}
            className=" margininvoice"
          >
            <div >
              <p style={{ fontSize: "20px" }}>Email Information</p>
              <hr />

              {allData && allData != null ?
                <>
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >

                    WebSite Name
                  </p>
                  <TextField
                    fullWidth
                    value={allData.website}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, website: inputText });
                    }}

                  />

                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >

                    Logo
                  </p>
                  <TextField
                    fullWidth
                    value={allData.logo || "no"}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, logo: inputText });
                    }}
                  />

                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >

                    Favicon
                  </p>
                  <TextField
                    fullWidth
                    value={allData.favicon}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, favicon: inputText });
                    }}
                  />

                  <br />

                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
                      <Button
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                        onClick={handleUpdateAdmin}
                      >
                        Update
                      </Button>

                      <Button
                        sx={{
                          // backgroundColor: "#9B56FF",
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>

                </>

                : "no data found"

              }


              <br />

      
            </div>
          </Box>

          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              padding: " 0px 20px",
              borderRadius: "10px",
              width: "100%",
            }}
            className=" margininvoice"
          >
            <div style={{}}>
              <p style={{ fontSize: "20px" }}>Address Details</p>
              <hr />




              {allData && allData !== null ?

                <>
                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >

                    Country
                  </p>
                  <TextField
                    fullWidth
                    value={allData.country}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, country: inputText });
                    }}

                  />


                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >

                    State
                  </p>
                  <TextField
                    fullWidth
                    value={allData.state}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, state: inputText });
                    }}

                  />

                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    City
                  </p>
                  <TextField
                    fullWidth
                    value={allData.city}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, city: inputText });
                    }}

                  />


                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Postal Code
                  </p>
                  <TextField
                    fullWidth
                    value={allData.zipCode}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, zipCode: inputText });
                    }}

                  />



                  <p
                    style={{
                      fontSize: "17px",
                      padding: "0px 5px 0px 0px ",
                    }}
                  >
                    Street
                  </p>
                  <TextField
                    fullWidth
                    value={allData.street}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setAllData({ ...allData, street: inputText });
                    }}

                  />



                  <br />



                  <div style={{ display: "flex", margin: "10px" }}>
                    <div style={{ display: "flex", gap: "10px", }}>
                      <Button
                        onClick={handleUpdateAdmin}
                        sx={{
                          backgroundColor: "#9B56FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Update
                      </Button>

                      <Button
                        sx={{
                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>




                </> : "no data found"


              }





              <br />
            </div>{" "}
          </Box>
        </Box>

      

        {/* Success Snackbar */}
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="User deleted successfully"
        />
      </Box>
      {loading && <Loader />}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(SmptMail);
