import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";



const Agents = ({ isLogging }) => {

 





  const openPopup = () => {
    navigate("/create-agent");

  }

  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [lead, setLead] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const token = getCookie("leadmanagement")

  useEffect(() => {
    fetchData()
  }, [isDelete])

  const fetchData = async () => {

    try {
      const response = await Api.getAllAgentUser(token)
      if (response.data && Array.isArray(response.data.data)) {
        setLead(mapPlotData(response.data.data));
        console.log(response.data.data);

      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    !isLogging && navigate('/')
  }, [])



  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {


      await Api.deleteAgent(selectedUserId);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);

    } catch (error) {

      console.error("Error deleting user:", error);
    }
  };



  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    {
      field: "full_name", headerName: " Name", width: 120,

      renderCell: (params) => (
        <Link to={`/agent-details/${params.row.id}`}>
          {params.value}
        </Link>
      ),

    },


    { field: 'email', headerName: 'Email ', width: 120 },
    { field: 'phone', headerName: 'Phone', width: 170 },
    { field: 'position', headerName: 'Position', width: 170 },
    { field: 'department', headerName: 'Department', width: 170 },
    {
      field: 'edit_user',
      headerName: 'Edit User'
      , width: 100,
      renderCell: (params) => (
        <Link to={`/edit-agent/${params.row.id}`}>
          <EditIcon />
        </Link>
      ),


    }

    , {
      field: 'delete_user',
      headerName: 'Delete User'
      , width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleDeleteClick(params.row.id)}
        ><DeleteForeverOutlinedIcon /></button>
      ),


    }

    ,

    , {
      field: 'action',
      headerName: 'Action',
      width: 40,
      renderCell: () => (
        <img src="/img/dots3.png" alt="" />
      ),
    },





  ];




  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      full_name: d.firstName || "No",
      title: d.title || "No",
      company: d.industry || "No",
      phone: d.mobileNumber,
      email: d.email,
      department: d.department,
      position: d.position,
      lead_created: d.createdAt.slice(0, 10),


    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad" >

        <Header title="Users" subTitle="Add User" openPopup={openPopup} />
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px"
            },

          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={lead}
            columns={columns}
          />
        </Box>


      
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>






      </Box>
    </>
  );
};

export default Agents;
