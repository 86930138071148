import React, { useState ,useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import Loader from "../Loader";
import { getCookie } from "../../components/Cookies";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditManager = ({ onClose }) => {

  const { id } = useParams();
  const navigate = useNavigate()
  const token = getCookie("leadmanagement")
console.log("token sdg" , token)
  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading , setLoading] = useState(false)
  const [leadData, setLeadData] = useState()
  useEffect(() => {
    fetchData();
  }, []);



  const handleChangePasswords = async() => {
try {

  if(newPassword.length < 8 ){
  toast.error("Please enter new password");
} 
if(newPassword !== confirmNewPassword){
  toast.error("New password should not be same as current password");
}

if (!newPassword) {
  return toast.error("fill new password");
}

setLoading(true);

const response = await Api.changePassword(id,{newPassword },token);

if(response.data.message === "password changed successfully"){
  setChangePassword(!changePassword)
  setLoading(false);
  toast.success("Password updated successfully");
}

setNewPassword("");
setConfirmNewPassword("");
} catch(error){
  setLoading(false);
  console.error("Error changing password:", error);
  toast.error(error.response.data.message);
}


  }


  const fetchData= async ()=>{

    try {
      setLoading(true)
      const response= await Api.getManagerById(id);
      setLoading(false)
        console.log(response.data.data)
        setLeadData(response.data.data)
    
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  }


  const backToPage = () => {
    navigate("/users")
  }









  const handleUpdate = async () => {
    try {
      setLoading(true)
      const response = await Api.editManager(id, { ...leadData } );


      console.log("API Response:", response);

      if (response.status === 200) {
        setLoading(false)
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/users"),
        });
        
      }
    } catch (error) {
      setLoading(false)
      console.error("Error updating property:", error);
    }
  };










  return (
    <>

<Topbar />

    {leadData && (
      <>
    <Box m="20px" className="margin_in_mobile_ipad"



>





<div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Contact Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>


<TextField
className="class_input"
id="outlined-controlled"
label="First Name"
value={leadData.firstName}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setLeadData({ ...leadData, firstName: inputText });
}
}}
variant="outlined"
/>

<TextField
className="class_input"
id="outlined-controlled"
label="Last Name"
value={leadData.lastName}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setLeadData({ ...leadData, lastName: inputText });
}
}}
variant="outlined"
/>


<TextField
className="class_input"
id="outlined-controlled"
label="Email"
value={leadData.email}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 35) {
setLeadData({ ...leadData, email: inputText });
}
}}
variant="outlined"
/>





<TextField
type="number"
className="class_input"
id="outlined-controlled"
label="Phone"
value={leadData.mobileNumber}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setLeadData({ ...leadData, mobileNumber: inputText });
}
}}
variant="outlined"
/>


</Box>



</Box>





<Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
<Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Additional Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

<Grid >
<div>Position</div>
<FormControl fullWidth >

<Select
sx={{height:"40px"}}
labelId="second-select-label"
id="second-select"
fullWidth
value={leadData.position}
onChange={(e) => setLeadData({ ...leadData, position: e.target.value })}
>
<MenuItem value="Manager">Manager</MenuItem>
<MenuItem value="Agent">Agent</MenuItem>


</Select>
</FormControl>
</Grid>

<Grid >
<div>Department</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="third-select-label"
id="third-select"
fullWidth
defaultValue=""
value={leadData.department}
onChange={(e) => setLeadData({ ...leadData, department: e.target.value })}
>
<MenuItem value="sales">Sales</MenuItem>
<MenuItem value="marketing">Marketing</MenuItem>
<MenuItem value="product_development">Product Development</MenuItem>
<MenuItem value="management">Management</MenuItem>

</Select>
</FormControl>
</Grid>





</Box>
</Box>

</div>




  <br />

    <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>


   <div style={{ display: "flex",  padding: "20px" }}>
    <div style={{ display: "flex", gap: "10px" }}>

      <Button sx={{
        // backgroundColor: "#9B56FF", 
        color: "#9B56FF",
        border: " 1px solid #9B56FF",
        '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

      onClick={() => {
        handleUpdate();
      }}
      >Update</Button>


      <Button sx={{
        backgroundColor: "#9B56FF", color: "white", '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

        onClick={backToPage}> Cancel</Button>

    </div>
  </div>
 

</div>

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Change Password

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>



<TextField
className="class_input"
type="password"
id="outlined-controlled"
label="New password"
value={newPassword}
onChange={(e) => {
const inputText = e.target.value;
setNewPassword(inputText);

}}
variant="outlined"
/>







<TextField
className="class_input"
id="outlined-controlled"
type="password"
label="Confim Password"
value={confirmNewPassword}
onChange={(e) => {
const inputText = e.target.value;

setConfirmNewPassword(inputText);

}}
variant="outlined"
/>


<div style={{ display: "flex", marginTop:"-3px",  alignItems: "center"}}>


<div style={{ display: "flex",  padding: "20px" }}>
 <div style={{ display: "flex", gap: "10px" }}>

   <Button sx={{
   
     color: "#9B56FF",
     border: " 1px solid #9B56FF",
     '&:hover': {
       backgroundColor: '#ffffff',
       color: "#9B56FF",
       border: " 1px solid #9B56FF"
     }
   }}

   onClick={() => {
    handleChangePasswords();
   }}
   >Change Password</Button>


   <Button sx={{
     backgroundColor: "#9B56FF", color: "white", '&:hover': {
       backgroundColor: '#ffffff',
       color: "#9B56FF",
       border: " 1px solid #9B56FF"
     }
   }}

     onClick={backToPage}> Cancel</Button>

 </div>
</div>


</div>


</Box>

</Box>

<Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"

}}    >
<Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

</Box>
</Box>

</div>


</div>
{loading && <Loader />}
  <ToastContainer/>
</Box>
      </>
    )}
    </>
  

  );
};

export default EditManager;
